/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./askfeedback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./askfeedback.component";
import * as i7 from "@angular/router";
import * as i8 from "../services/feedback.service";
var styles_AskfeedbackComponent = [i0.styles];
var RenderType_AskfeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AskfeedbackComponent, data: {} });
export { RenderType_AskfeedbackComponent as RenderType_AskfeedbackComponent };
export function View_AskfeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "askForFeedbackWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "askForFeedbackBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "askIntro regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thank you for using CHIT!"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Your call has ended. "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [["class", "askForFeedback"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Would you like to leave feedback? "])), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "askButtons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", "bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerYes() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" YES "])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.answerNo() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" NO "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 10).disabled || null); var currVal_1 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 13).disabled || null); var currVal_3 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_2, currVal_3); }); }
export function View_AskfeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-askfeedback", [], null, null, null, View_AskfeedbackComponent_0, RenderType_AskfeedbackComponent)), i1.ɵdid(1, 114688, null, 0, i6.AskfeedbackComponent, [i7.Router, i8.FeedbackService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AskfeedbackComponentNgFactory = i1.ɵccf("app-askfeedback", i6.AskfeedbackComponent, View_AskfeedbackComponent_Host_0, {}, {}, []);
export { AskfeedbackComponentNgFactory as AskfeedbackComponentNgFactory };
