<div class="introWrapper">
    <div class="introTextWrapper">
        <!-- <div class="introText regularWeight">
            Chit is a Simple P2P Video Chat Service. 
        </div> -->
        <div class="introText regularWeight">
            Chit is a simple P2P Video Chat Service that runs in the Browser. 
        </div>
        <br>
        <!-- <div class="introText regularWeight">
            Sessions connect users to one another directly and securely. 
        </div> -->
        <div class="introText regularWeight">
            Sessions connect users to one another directly, securely and privately.
        </div>
        <br>
        <div class="introText regularWeight">
            The contents of your call do not pass through our server.
        </div>
    </div>
    <div class="introButtonsWrapper">
        <div >
            <button class="introButtons bigButton" mat-raised-button (click)="giveItATry()">GIVE IT A TRY</button>
        </div>
        <!-- <div >
            <button class="introButtons" mat-raised-button (click)="signMeUp()">SIGN UP</button>
        </div> -->
    </div>

    <div class="introTextWrapper">
        <div class="introText regularWeight">
            We are also building enhanced features for registered users, so if you like the service, consider joining the waiting list.
        </div>
    </div>

    <div class="introButtonsWrapper">
        <div >
            <button class="introButtons bigButton" mat-raised-button (click)="join()">JOIN</button>
        </div>
    </div>
</div>
<!-- <div class="usecasesWrapper">
    <a routerLink="/usecases" class="navigationLink fontWhite lightWeight">Use Cases</a>
</div> -->