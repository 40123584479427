<div class="connectionBox" *ngIf="!callError">
    <div class="connectionTextWrapper regularWeight">
        <div class="connectionTextTop">
            Calling
        </div>
        <div class="connectionTextBottom">
            {{peerid}}
        </div>
        <div>
            <object type="image/svg+xml" data="./assets/image/BarsAnimate.svg" class="logo">
            </object>
        </div>
    </div>
    <div class="awaitingText regularWeight">
        Initializing Session with: {{peerid}}...
    </div>
</div>

<div class="connectionBox" *ngIf="callError">
    <div class="connectionTextWrapper regularWeight">
        <div class="connectionTextTop">
            Could Not
        </div>
        <div class="connectionTextBottom">
            Connect
        </div>
        <div>
            <object type="image/svg+xml" data="./assets/image/Error.svg" class="logo">
            </object>
        </div>
    </div>
    <div class="awaitingText regularWeight">
        {{errorMessage}}
    </div>
</div>
