/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./feedback.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../star/star.component.ngfactory";
import * as i4 from "../star/star.component";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../waiting/waiting.component.ngfactory";
import * as i10 from "../waiting/waiting.component";
import * as i11 from "@angular/common";
import * as i12 from "./feedback.component";
import * as i13 from "../services/feedback.service";
import * as i14 from "@angular/router";
var styles_FeedbackComponent = [i0.styles];
var RenderType_FeedbackComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FeedbackComponent, data: {} });
export { RenderType_FeedbackComponent as RenderType_FeedbackComponent };
function View_FeedbackComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 540672, null, 0, i2.FormGroupDirective, [[8, null], [8, null]], { form: [0, "form"] }, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.FormGroupDirective]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(6, 0, null, null, 1, "label", [["class", "labelMargin"], ["id", "starrateings"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["How would you rate your experience?"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-star", [], null, [[null, "starred"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("starred" === en)) {
        var pd_0 = (_co.starFeedback($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_StarComponent_0, i3.RenderType_StarComponent)), i1.ɵdid(9, 114688, null, 0, i4.StarComponent, [], null, { starred: "starred" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "button", [["class", "submitButton bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(11, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["SUBMIT"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.feedbackForm; _ck(_v, 3, 0, currVal_7); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_8 = (i1.ɵnov(_v, 11).disabled || null); var currVal_9 = (i1.ɵnov(_v, 11)._animationMode === "NoopAnimations"); _ck(_v, 10, 0, currVal_8, currVal_9); }); }
function View_FeedbackComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "textWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "thankyouText semiboldWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Thank you for your thoughts! "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "meantimeText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We are committed to building a better experience. "])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "makecallWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "button", [["class", "introButtons bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.join() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(9, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["JOIN"])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "joinButton bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.continue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.FocusMonitor, [2, i8.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, [" CONTINUE "]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 9).disabled || null); var currVal_1 = (i1.ɵnov(_v, 9)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 13).disabled || null); var currVal_3 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_2, currVal_3); }); }
export function View_FeedbackComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-waiting", [], null, null, null, i9.View_WaitingComponent_0, i9.RenderType_WaitingComponent)), i1.ɵdid(1, 114688, null, 0, i10.WaitingComponent, [], { shouldwait: [0, "shouldwait"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "feedbackWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackComponent_1)), i1.ɵdid(4, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FeedbackComponent_2)), i1.ɵdid(6, 16384, null, 0, i11.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.processing; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.submitted; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.submitted; _ck(_v, 6, 0, currVal_2); }, null); }
export function View_FeedbackComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-feedback", [], null, null, null, View_FeedbackComponent_0, RenderType_FeedbackComponent)), i1.ɵdid(1, 245760, null, 0, i12.FeedbackComponent, [i13.FeedbackService, i14.Router, i2.FormBuilder], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackComponentNgFactory = i1.ɵccf("app-feedback", i12.FeedbackComponent, View_FeedbackComponent_Host_0, {}, {}, []);
export { FeedbackComponentNgFactory as FeedbackComponentNgFactory };
