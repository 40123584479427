import { Injectable } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import { ErrorMessageSheetComponent  }  from '../error-message-sheet/error-message-sheet.component';
import { stringify } from 'querystring';
@Injectable({
  providedIn: 'root'
})
export class ErrorMessageService {

  constructor(private _bottomSheet: MatBottomSheet) { }

  openErrorBottomSheet(obj:any){
    if(obj.type=='string'){
      //display string message
    }
    var bottonmSheetRef=this._bottomSheet.open(ErrorMessageSheetComponent, {
      data: obj,
      disableClose : true
    });
    bottonmSheetRef.afterDismissed().subscribe( (res)=>{
    })
  }
  openErrorBottomSheetV2(err:any){
    var obj;
    if(err instanceof DOMException ){
      let errorName=err.name;
      let errorMessage=err.message;
      let error=errorName + ' : ' + errorMessage;
      obj={
        type: 'string',
        message : error
      }
    }
    else if((typeof err).toLowerCase() =='string'){
      obj={
        type: 'string',
        message : err
      }
    }
    else if((typeof err).toLowerCase() =='object'){
      obj={
        type : 'object',
        object: err
      }
    }
    var bottonmSheetRef=this._bottomSheet.open(ErrorMessageSheetComponent, {
      data: obj,
      disableClose : true
    });
    bottonmSheetRef.afterDismissed().subscribe( (res)=>{
    })
  }
}
