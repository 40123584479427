import { Component, OnInit, Inject } from '@angular/core';
import { MatBottomSheetRef , MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-error-message-sheet',
  templateUrl: './error-message-sheet.component.html',
  styleUrls: ['./error-message-sheet.component.css']
})
export class ErrorMessageSheetComponent implements OnInit {
  messageString='';
  messageObject=null;
  constructor(private _bottomSheetRef: MatBottomSheetRef<ErrorMessageSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any) { 
      if(data.type=='string'){
        this.messageString=data.message;
      }
      else if(data.type=='object'){
        this.messageObject=data.object;
      }
      else if(data.type=='both'){
        this.messageString=data.message;
        this.messageObject=data.object;
      }
      else {
        this.messageString='Unknown error type';
      }
    }

  ngOnInit() {
  }

  dismiss(){
    this._bottomSheetRef.dismiss();
  }
}
