import * as socketio from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { first } from 'rxjs/operators';
import * as i0 from "@angular/core";
export class RealTimeService {
    constructor() {
        // this._init();
    }
    attachServer() {
        return new Promise((resolve, reject) => {
            this.socket = socketio(environment.socket.baseURL, environment.socket.opts);
            //console.log(this.socket.connected)
            this.listen('connect').pipe(first()).subscribe(_ => {
                //console.log(`Connection to Server made @ ${this.socket.id}`);
                resolve(this.socket.id);
            });
        });
        // console.log(this.socket);
    }
    get id() { return this.socket.id; }
    emit(event, data) {
        // console.log(`%c [Socket] <${event}>, Payload: ${typeof data === 'string' ? data : JSON.stringify(data)}`, 'color: green');
        this.socket.emit(event, data);
    }
    once(event) {
        return new Observable(obs => {
            this.socket.once(event, data => {
                // console.log(`%c [Socket] <${event}> Received ${typeof data === 'string' ? data : JSON.stringify(data)}`, 'color: green');
                obs.next(data);
            });
            return () => this.socket.off(event);
        });
    }
    listen(event) {
        return new Observable(obs => {
            this.socket.on(event, data => {
                // console.log(`%c [Socket] <${event}> Received ${typeof data === 'string' ? data : JSON.stringify(data)}`, 'color: green');
                obs.next(data);
            });
            return () => this.socket.off(event);
        });
    }
    disconnect() {
        this.socket.disconnect();
    }
}
RealTimeService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RealTimeService_Factory() { return new RealTimeService(); }, token: RealTimeService, providedIn: "root" });
