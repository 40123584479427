import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { VanillartcService } from './vanillartc.service';
import { FeedbackService } from './feedback.service';
@Injectable({
  providedIn: 'root'
})
export class RouteGuardService implements CanActivate {
  constructor(private router: Router, private rtcService : VanillartcService,
      private feedbackService : FeedbackService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    return this.getValidName();
  }

  getValidName(){
    let Rname=this.rtcService.myname;
    //let call=this.feedbackService.call;
    let Fname=this.feedbackService.myname;
    //console.log(Rname);
    //console.log(call);
    //console.log(Fname);
    //get callid since rtcservice name is already reset by feedback section
    //actually get name from feedback service on off chance callee never got callid from caller
    //this still allows the route to go through, since your name was made regardless of callid
    //if( (Rname!=='' && Rname!==undefined) || call!==''){
    if( (Rname!=='' && Rname!==undefined) || Fname!==''){
      return true;
    }
    else{
      this.router.navigate(['']);
      return false;
    }

  }
}
