import { Routes } from '@angular/router';
import { IntroComponent } from './intro/intro.component';
import { PresessionComponent } from './presession/presession.component';
import { JoinComponent } from './join/join.component';
import { CallingComponent } from './calling/calling.component';
import { AnswerCallComponent } from './answer-call/answer-call.component';
import { AskfeedbackComponent } from './askfeedback/askfeedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MobilesessionComponent } from './mobilesession/mobilesession.component';
import { InfoComponent } from './info/info.component';
import { RouteGuardService as RouteGuard } from './services/route-guard.service';
const ɵ0 = { state: 'intro' }, ɵ1 = { state: 'join' }, ɵ2 = { state: 'info' }, ɵ3 = { state: 'presession' }, ɵ4 = { state: 'answercall' }, ɵ5 = { state: 'call' }, ɵ6 = { state: 'mobilesession' }, ɵ7 = { state: 'askforfeedback' }, ɵ8 = { state: 'feedback' };
const routes = [
    { path: '', component: IntroComponent, data: ɵ0 },
    //test below
    //{ path : '', component : MobiletestComponent, data : { state : 'mobiletest'}},
    //{ path : '', component : MobilesessionComponent, data : { state : 'mobilesession'}},
    //{ path : '', component : FeedbackComponent, data : { state : 'feedback'}},
    //{ path : 'login', component : LoginComponent, data : { state : 'login'}},
    { path: 'join', component: JoinComponent, data: ɵ1 },
    //{ path : 'signup', component : SignupComponent, data : { state : 'signup'}},
    { path: 'info', component: InfoComponent, data: ɵ2 },
    { path: 'presession', component: PresessionComponent, data: ɵ3 },
    //{ path : 'session', component : SessionComponent, data : { state : 'session'}},
    //{ path : 'usecases', component : UsecasesComponent, data : { state : 'usecases'}},
    //{ path : 'sessionflyout', component : SessionChatFlyoutComponent, data : { state : 'sessionflyout'}},
    //{ path : 'mobiletest', component : MobiletestComponent, data : { state : 'mobiletest'}},
    { path: 'answercall', component: AnswerCallComponent, canActivate: [RouteGuard], data: ɵ4 },
    { path: 'call', component: CallingComponent, canActivate: [RouteGuard], data: ɵ5 },
    { path: 'mobilesession', component: MobilesessionComponent, canActivate: [RouteGuard], data: ɵ6 },
    { path: 'askforfeedback', component: AskfeedbackComponent, canActivate: [RouteGuard], data: ɵ7 },
    { path: 'feedback', component: FeedbackComponent, canActivate: [RouteGuard], data: ɵ8 },
    { path: '**', redirectTo: '' }
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
