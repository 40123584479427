import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { IntroComponent } from './intro/intro.component';
import { SessionComponent } from './session/session.component';
import { HeaderComponent } from './header/header.component';
import { PresessionComponent } from './presession/presession.component';
import { VideoComponent } from './video/video.component';
import { UsecasesComponent } from './usecases/usecases.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ErrorMessageSheetComponent } from './error-message-sheet/error-message-sheet.component';
import { SessionChatFlyoutComponent } from './session-chat-flyout/session-chat-flyout.component';
import { MobiletestComponent } from './mobiletest/mobiletest.component';
import { JoinComponent } from './join/join.component';
import { CallingComponent } from './calling/calling.component';
import { AnswerCallComponent } from './answer-call/answer-call.component';
import { SentryErrorHandler } from './sentry.errorhandler';
import { AskfeedbackComponent } from './askfeedback/askfeedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MobilesessionComponent } from './mobilesession/mobilesession.component';
import { InfoComponent } from './info/info.component';
import { StarComponent } from './star/star.component';
import { WaitingComponent } from './waiting/waiting.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    IntroComponent,
    SessionComponent,
    HeaderComponent,
    PresessionComponent,
    VideoComponent,
    UsecasesComponent,
    ConfirmationDialogComponent,
    ErrorMessageSheetComponent,
    SessionChatFlyoutComponent,
    MobiletestComponent,
    JoinComponent,
    CallingComponent,
    AnswerCallComponent,
    AskfeedbackComponent,
    FeedbackComponent,
    MobilesessionComponent,
    InfoComponent,
    StarComponent,
    WaitingComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DeviceDetectorModule.forRoot()
  ],
  //providers: [],
  providers: [{
    provide: ErrorHandler,
    useClass: SentryErrorHandler
  }],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmationDialogComponent, ErrorMessageSheetComponent
  ]
})
export class AppModule { }
