import { Component, OnInit } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { FeedbackService } from '../services/feedback.service';
@Component({
  selector: 'app-askfeedback',
  templateUrl: './askfeedback.component.html',
  styleUrls: ['./askfeedback.component.css']
})
export class AskfeedbackComponent implements OnInit {

  constructor(private router : Router, private feedbackService :FeedbackService) { }

  ngOnInit() {
  }


  answerYes(){
    this.router.navigate(['feedback']);

  }

  answerNo(){
    this.feedbackService.autoUpdateFeedback();
    this.router.navigate(['presession']);
  }
}
