import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PeerconnectorService } from '../services/peerconnector.service';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { VanillartcService } from '../services/vanillartc.service';

@Component({
  selector: 'app-answer-call',
  templateUrl: './answer-call.component.html',
  styleUrls: ['./answer-call.component.css']
})
export class AnswerCallComponent implements OnInit, OnDestroy {
  usemobilesession=false;
  peerid:string ='';
  peeridSubscription:Subscription;
  dataSubscription:Subscription;
  constructor(private router : Router, private rtcService: VanillartcService) {

  }

  ngOnInit() {
    this.peerid = this.rtcService.peer;
    this.usemobilesession = true;
  }

  answer(accept){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          initiated: false
      }
    }
    this.rtcService.acceptCall(accept);
    if (!accept) {
      this.router.navigate(['presession']);
    }
    else if(this.usemobilesession){
      this.router.navigate(['mobilesession'], navigationExtras);
    }
    else{
      this.router.navigate(['session'], navigationExtras)
    }
    //this.router.navigate(['session'], navigationExtras);
  }

  ngOnDestroy(){
  }
}
