import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '../services/data.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.css']
})
export class JoinComponent implements OnInit {
  joined=false;
  alreadysignedup=false;
  joinerror=false;
  joinForm : FormGroup;
  dataURL=environment.dataURL;
  processing = false;

  // form
  emailControlName = '';
  joinFormControlList = [];

  constructor(private router :Router, private dataService : DataService, private fb: FormBuilder) {

   }

   createForm(){
    this.emailControlName = this.getRandomString('emailjoin');
    this.joinForm = this.fb.group({
      [`${this.emailControlName}`]: ['', [Validators.required, Validators.email ]],
      name: ['', [Validators.required]],
      address: ['', [Validators.required]],
    });
  }
  ngOnInit() {
    this.createForm();
    this.joinFormControlList = this.getKeys(this.joinForm);
  }

  getKeys(form) {
    return Object.keys(form.controls);
  }

  getRandomString(controlname) {
    const arr = new Uint32Array(3);
    const cryptoObj = (window as any).crypto || (window as any).msCrypto;
    if (!cryptoObj) {
      return controlname;
    }

    const rands = cryptoObj.getRandomValues(arr);
    const val = rands.reduce((a, b) => a+b, 0)
    return /^[a-zA-Z]/.test(val) ? val : `e${val}e`;
  }

  isInvalid(form, controlname) {
    return form.get(controlname).invalid;
  }

  join(form){
    if (form.valid) {
      console.log(`So, You filled out even hidden inputs, Congrats!`);
      return;
    }
    const vals = (Object as any).assign({}, form.value);
    if(vals[this.emailControlName] && !vals.name && !vals.email){
      this.processing = true;
      const email=vals[this.emailControlName];
      this.dataService.postData(this.dataURL + '/mails', { email }).subscribe( (dt)=>{
        this.joined=true;
        this.joinerror=false;
        this.processing = false;
      }, (err)=>{
        const { statusText, status } = err;
        if (status === 409) {
          this.joined = false;
          this.alreadysignedup = true;
        } else {
          Sentry.captureException(err);
          this.joined=false;
          this.joinerror=true;
        }
        this.processing = false;
      })
    }
  }

  makeCall(){
    this.router.navigate(['presession', {id : null}]);
  }
}
