import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent implements OnInit {

  @Output('starred') starred = new EventEmitter<number>();
  StarCount = Array(5).fill(1);

  constructor() { }

  ngOnInit() {
  }

  startClicked(starIdx: number): void {
    // console.log(starIdx + 1);
    this.StarCount.fill(0, 0, starIdx + 1);
    this.StarCount.fill(1, starIdx + 1);
    // this.starValue = starIdx + 1;
    this.starred.emit(starIdx + 1);
  }

}
