import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { ErrorMessageSheetComponent } from '../error-message-sheet/error-message-sheet.component';
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/bottom-sheet";
export class ErrorMessageService {
    constructor(_bottomSheet) {
        this._bottomSheet = _bottomSheet;
    }
    openErrorBottomSheet(obj) {
        if (obj.type == 'string') {
            //display string message
        }
        var bottonmSheetRef = this._bottomSheet.open(ErrorMessageSheetComponent, {
            data: obj,
            disableClose: true
        });
        bottonmSheetRef.afterDismissed().subscribe((res) => {
        });
    }
    openErrorBottomSheetV2(err) {
        var obj;
        if (err instanceof DOMException) {
            let errorName = err.name;
            let errorMessage = err.message;
            let error = errorName + ' : ' + errorMessage;
            obj = {
                type: 'string',
                message: error
            };
        }
        else if ((typeof err).toLowerCase() == 'string') {
            obj = {
                type: 'string',
                message: err
            };
        }
        else if ((typeof err).toLowerCase() == 'object') {
            obj = {
                type: 'object',
                object: err
            };
        }
        var bottonmSheetRef = this._bottomSheet.open(ErrorMessageSheetComponent, {
            data: obj,
            disableClose: true
        });
        bottonmSheetRef.afterDismissed().subscribe((res) => {
        });
    }
}
ErrorMessageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorMessageService_Factory() { return new ErrorMessageService(i0.ɵɵinject(i1.MatBottomSheet)); }, token: ErrorMessageService, providedIn: "root" });
