import { Component, OnInit, OnDestroy  } from '@angular/core';
import { PeerconnectorService} from '../services/peerconnector.service';
import { Router, ActivationEnd } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VanillartcService } from '../services/vanillartc.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  useFeedback=false;
  myid='';
  peerid:string='';
  myIdSubscription:Subscription;
  peerDataSubscription : Subscription;
  routerSubscription:Subscription;
  cancelCallSub:Subscription;
  takeUntilSubject = new Subject();
  state="intro";
  useHeader=true;
  showFullHeader=true;
  useLeftArrow=false;
  constructor( private router :Router, private rtcService: VanillartcService) {
    this.useFeedback=this.rtcService.getUseFeedback();
    this.myIdSubscription=this.rtcService.myIdSubject.pipe(
      takeUntil(this.takeUntilSubject)
    ).subscribe( (name)=>{
      if(name){
        this.myid=name;
      } else {
        this.myid = '';
      }
    })
    this.routerSubscription=this.router.events.pipe(
      takeUntil(this.takeUntilSubject)
    ).subscribe( (ev)=>{
      if (ev instanceof ActivationEnd) {
        let state=ev.snapshot.data.state;
        this.state=state;
        switch(state) {
          case 'intro':
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'signup':
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
          case 'login':
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
          case 'presession':
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
          case 'usecases':
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
          case 'session':
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'mobiletest' : //and for dekstop
            this.showFullHeader=false;
            this.useLeftArrow=false;
            break;
          case 'mobilesession' :
            this.showFullHeader=false;
            this.useLeftArrow=false;
            break;
          case 'answercall' :
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'call' :
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'askforfeedback' :
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'feedback' :
            this.useHeader=false;
            this.showFullHeader=true;
            this.useLeftArrow=false;
            break;
          case 'info' :
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
          case 'join' : 
            this.useHeader=true;
            this.showFullHeader=true;
            this.useLeftArrow=true;
            break;
        }
      }
    })

  }
  ngOnInit() {
    this.rtcService.cancelCallSubject.pipe(
      takeUntil(this.takeUntilSubject)
    ).subscribe(cancel => {
      if (cancel) {
        this.backToPresession();
      }
    })
  }

  copyToClipBoard(){
    //console.log('copyIcon clicked');
    let newClipText=this.myid;
    var promise = navigator.clipboard.writeText(newClipText);
    promise.then( ()=>{

    },
    err=>{
    })
  }

  endSession(){
    if(!this.useFeedback){
      this.backToPresession();
    }
    else{
      //disconnect from session but save my used id string????
      this.goToAskForFeedback();
    }
  }

  goToAskForFeedback(){
    // this.resetConnections();
    //pass in your id if you still needed it after logging out;
    this.router.navigate(['askforfeedback']);
  }

  goToInfo(){
    this.router.navigate(['info']);
  }
  backToIntro(){
    // this.resetConnections();
    this.router.navigate(['']);
  }

  backToPresession(){
    // this.resetConnections();
    this.router.navigate(['presession']);
  }

  // resetConnections(){
  //   let connectedToServer=this.peerservice.isPeerConnectedToServer();
  //   console.log(connectedToServer);
  //   if(connectedToServer){
  //     this.peerservice.disconnectFromServer();
  //   }
  //   this.peerservice.resetPeerService();
  //   this.myid='';
  //   this.peerid='';
  // }

  ngOnDestroy(){
    // if(this.peerDataSubscription){
    //   this.peerDataSubscription.unsubscribe();
    // }
    // if(this.myIdSubscription){
    //   this.myIdSubscription.unsubscribe();
    // }
    // if(this.routerSubscription){
    //   this.routerSubscription.unsubscribe();
    // }
    this.takeUntilSubject.next()
    this.takeUntilSubject.complete();
  }

  cancelCall(){
    //this.myid='';
    this.rtcService.cancelCall();
    //this.peerservice.cancelDataConnection();
    this.router.navigate(['presession']);
    //send message to callee that you cancelled
    //or have on disconnect on callee deal with it
  }

  rejectCall(){
    //this.myid='';
    this.rtcService.acceptCall(false);
    // this.peerservice.cancelDataConnection();
    this.router.navigate(['presession']);
    //send message to caller that you cancelled
    //or have on disconnect on caller deal with it
  }

  goToAskFeedback(){
    // this.resetConnections();
    this.router.navigate(['askfeedback']);
  }
}
