<div class="connectionBox">
    <div class="connectionTextWrapper regularWeight">
        <div class="connectionText">
            Incoming Connection From
        </div>
        <!-- <br> -->
        <div class="connectionText">
            {{peerid}}
        </div>
    </div>
    <button mat-raised-button class="answerButton bigButton" (click)="answer(1)">
        ANSWER
    </button>
    <button mat-raised-button class="answerButton bigButton" (click)="answer(0)">
        Reject
    </button>
    <div class="awaitingText regularWeight">
        Awaiting Confirmation
    </div>
</div>
