import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) { }

  postData(url: string, data) {
    return this.http.post<any>(url, data, this.httpOptions);
  }

  putData(url:string, data) {
    return this.http.put<any>(url, data, this.httpOptions);
  }
  
  getData(url: string) {
    return this.http.get<any>(url);
  }
}
