<div class="sessionInit">
    <div class="createChitCodeBox" *ngIf="!connectedToServer">
        <div class="sessionInstructions regularWeight" >
            Your Chit Code is the TEMPORARY and UNIQUE identifier that lets other users contact you.
        </div>
        <div class="warn" [class.hidden]="!isNotSupported">
          {{this.supportMsg}}
        </div>
        <form class="chitCodeWrapper mediumWeight" novalidate [formGroup]="newChitForm" *ngIf="!connectedId && newChitControlList">
          <div class="looper" *ngFor="let control of newChitControlList;">
            <mat-form-field class="inputClass">
                <input type="text" autocomplete="off" autocapitalize="none" autocorrect="off"
                    matInput [formControlName]="control" placeholder="Create Chit Code" maxlength=16>
                <mat-hint align="start">16 character max length</mat-hint>
                <mat-error *ngIf="isInvalid(newChitForm, control)">{{getErrorMessage(newChitForm,control)}}</mat-error>
            </mat-form-field>
          </div>
          <button [disabled]="newChitForm.get(chitcodeControlName).invalid || isNotSupported" mat-raised-button class="connectButton bigButton" (click)="makeServerConnection(newChitForm)" *ngIf="!connectedId">
              CREATE
          </button>
        </form>
    </div>

    <div class="createChitCodeBox" *ngIf="connectedToServer">
        <div class="sessionInstructions regularWeight">
            Share your Chit Code and wait for a call.
            Or, you can call someone else if you have their Chit Code.
        </div>
        <form class="connectToWrapper" [formGroup]="connectChitForm" novalidate>
          <div class="looper" *ngFor="let control of connectChitControlList;">
            <mat-form-field class="inputClass">
                <input type="text" matInput placeholder="ENTER PEER'S CHIT CODE"  [formControlName]="control"
                    autocomplete="off" autocapitalize="none" autocorrect="off" maxlength=16>
                <mat-error *ngIf="isInvalid(connectChitForm, control)">{{getPeerErrorMessage(connectChitForm, control)}}</mat-error>
            </mat-form-field>
            <button [disabled]="connectChitForm.get(connectcodeControlName).invalid" mat-raised-button class="connectButton bigButton" (click)="makeDataAndVideoConnectionAttempt(connectChitForm)">
                CALL
            </button>
          </div>
        </form>
    </div>

    <div class="subInstructionsWrapper">
        <div class="sessionInstructions">
            <div class="subInstructionText">
                Your Chit Code is good until your call is connected or you refresh the page.
            </div>
            <div class="subInstructionText">
                Once you've been connected, Chit drops out and nobody except your connected Peer can be a part of the call.
            </div>
            <!-- <div class="subInstructionText">
                The contents of the call exist solely on the connected Peer's devices and are not stored in any way, anywhere...webrtc magic.

            </div> -->
        </div>
    </div>
</div>
