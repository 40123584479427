import * as tslib_1 from "tslib";
import { BehaviorSubject, fromEvent, Subscription, Subject } from 'rxjs';
import { first, tap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./real-time.service";
import * as i2 from "@angular/common/http";
import * as i3 from "ngx-device-detector";
export class VanillartcService {
    constructor(socket, http, deviceService) {
        this.socket = socket;
        this.http = http;
        this.deviceService = deviceService;
        this.config = { iceServers: [
                { urls: 'stun:stun.l.google.com:19302' },
                { urls: 'stun:stun.voiparound.com' },
                { urls: 'stun:stun.voipbuster.com' },
                { urls: 'stun:stun.voipstunt.com' },
                { urls: 'stun:stun.xten.com' },
                { url: 'stun:global.stun.twilio.com:3478?transport=udp',
                    urls: 'stun:global.stun.twilio.com:3478?transport=udp' },
            ] };
        this.caller = false;
        this.utf8decoder = new TextDecoder();
        this.messages = [];
        this.myIdSubject = new BehaviorSubject('');
        this.messageSubject = new BehaviorSubject({});
        this.loggerSubject = new BehaviorSubject({});
        this.streamSubject = new BehaviorSubject({});
        this.connectionEvent = new BehaviorSubject({});
        this.cancelCallSubject = new BehaviorSubject(false);
        this.isSupported = new BehaviorSubject({});
        this.iceCandidates = [];
        this.vidConnected = false;
        this.CallerID = null;
        this.CalleeID = null;
        this.takeUntilSub = new Subject();
        this.takeUntilSubSocket = new Subject();
        // this.startListening();
        this.checkDeviceSupport();
    }
    registerSelf(name) {
        this.name = name;
        return this.socket.attachServer().then(resp => {
            return this.http.post(`${environment.socket.baseURL}/register`, { name, socketid: this.socket.id })
                .pipe(tap((res) => {
                if (res && res.msg === 'created') {
                    this.takeUntilSub = new Subject();
                    this.takeUntilSubSocket = new Subject();
                    this.startListening();
                    this.myIdSubject.next(res.name);
                }
            })).toPromise();
        });
    }
    canConnect(peername) {
        this.peername = peername;
        return this.http.post(`${environment.socket.baseURL}/connect`, { name: this.name, peername })
            .pipe(tap((res) => {
            if (res && res.peer && res.peer.socketid) {
                this.CalleeID = res.peer.socketid;
                this.CallerID = this.socket.id;
            }
        }));
    }
    get peer() { return this.peername; }
    get myname() { return this.name; }
    setPeerName(name) {
        this.peername = name;
    }
    getUseFeedback() {
        return true;
    }
    amICaller() {
        if (this.CallerID === this.socket.id) {
            return true;
        }
        else {
            return false;
        }
    }
    createConn(iamcaller, remoteOffer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            this.local = new RTCPeerConnection(this.config);
            this.caller = iamcaller;
            const stream = yield navigator.mediaDevices.getUserMedia({ video: true,
                audio: true });
            this.localStream = stream;
            this.streamSubject.next({ type: 'local', stream: this.localStream });
            for (const track of stream.getTracks()) {
                this.local.addTrack(track, stream);
            }
            // if (iamcaller) {
            this.sendChannel = this.local.createDataChannel('sendDataChannel', {
                negotiated: true,
                ordered: true,
                id: 0
            });
            this.plugDataChannel(this.sendChannel);
            this.plugEvents(this.local);
            if (remoteOffer) {
                this.acceptOffer(iamcaller, remoteOffer);
            }
        });
    }
    acceptOffer(iamcaller, remoteOffer) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                //console.log(`[Remote Offer] Attaching`);
                const { senderid, offer, name } = remoteOffer;
                this.CallerID = senderid;
                const rtcOffer = new RTCSessionDescription(JSON.parse(offer));
                if (this.local.signalingState !== 'stable') {
                    // polite implementation
                    // https://blog.mozilla.org/webrtc/perfect-negotiation-in-webrtc/
                    if (!iamcaller) {
                        return;
                    }
                    yield Promise.all([
                        this.local.setLocalDescription({ type: 'rollback' }),
                        this.local.setRemoteDescription(rtcOffer)
                    ]);
                }
                else {
                    yield this.local.setRemoteDescription(rtcOffer);
                }
                yield this.local.setLocalDescription(yield this.local.createAnswer());
                // this.local.setRemoteDescription(rtcOffer);
                // this.local.createAnswer().then( (ans: any) => {
                //   this.local.setLocalDescription(ans);
                this.loggerSubject.next({ type: 'log', msg: `webrtc Answer Sent` });
                this.socket.emit('answer2', { name: this.name, senderid, data: JSON.stringify(this.local.localDescription) });
                // }).catch(err => {
                // console.error(err);
                // });
            }
            catch (err) {
                //console.error(err);
            }
        });
    }
    sendMessage(msg) {
        this.messageSubject.next({ from: this.name, message: msg, whos: 'yours' });
        this.sendChannel.send(JSON.stringify({ type: 'msg', message: msg }));
    }
    sendAction(action) {
        this.sendChannel.send(JSON.stringify({ type: 'action', action }));
    }
    sendCallID(id) {
        this.sendChannel.send(JSON.stringify({ type: 'callid', callid: id }));
    }
    handleAnswer(ans) {
        const { senderid, data } = ans;
        this.CalleeID = senderid;
        const rtcAns = new RTCSessionDescription(JSON.parse(data));
        this.local.setRemoteDescription(rtcAns);
        // this.vidConnected = true;
        this.sendIceCandidate(this.local, senderid);
        // this.socket.emit('candidates', {name: this.name, peername: this.peername, data: 'candidate'});
    }
    plugDataChannel(channel) {
        const onOpenEvent = fromEvent(channel, 'open');
        const onCloseEvent = fromEvent(channel, 'close');
        const onMessageEvent = fromEvent(channel, 'message');
        onOpenEvent.pipe(first()).subscribe(_ => {
            //console.log(`[DATA CONNECTION] Opened`);
            // console.log(`[RTC CONNECTION TYPE] ${(this.local as any).iceTransportPolicy}`);
            this.local.getStats(null).then(stats => {
                // console.log(stats);
                stats.forEach(report => {
                    if (/(local|remote)\-candidate/i.test(report.type)) {
                        let logmsg = /remote/i.test(report.type) ? '[REMOTE] ' : '[LOCAL] ';
                        logmsg += `${report.candidateType} - ${report.protocol} - ${report.ip}:${report.port}`;
                        //console.log(logmsg);
                        this.loggerSubject.next({ type: 'log', msg: logmsg });
                    }
                });
                Object.keys(stats).forEach(key => {
                    if (stats[key].type === 'candidatepair' && stats[key].nominated && stats[key].state === 'succeeded') {
                        const remote = stats[stats[key].remoteCandidateId];
                        //console.log(`[Connected] ${remote.ipAddress}:${remote.portNumber} [Transport] ${remote.transport} ${remote.candidateType}`);
                    }
                });
            });
            this.loggerSubject.next({ type: 'log', msg: 'Data Opened' });
            // this.loggerSubject.next({type: 'action', msg: 'Data Opened', open: true});
        });
        onCloseEvent.pipe(first()).subscribe(_ => {
            //console.log(`[DATA CONNECTION] Closed`);
            this.loggerSubject.next({ type: 'log', msg: 'Connection Closed' });
            this.loggerSubject.next({ type: 'action', msg: 'Connection Closed', open: false });
            // this.connectionEvent.next({open: false});
        });
        onMessageEvent.pipe(takeUntil(this.takeUntilSub)).subscribe((ev) => {
            //console.log(`[REMOTE MESSAGE] ${ev.data}`);
            try {
                const msg = JSON.parse(ev.data);
                if (msg.hasOwnProperty('type') && msg.type === 'msg') {
                    this.messageSubject.next({ from: this.peername, message: msg.message, whos: 'theirs', date: Date.now() });
                }
                else if (msg.hasOwnProperty('type') && msg.type === 'action') {
                    this.messageSubject.next({ action: msg.action, date: Date.now() });
                }
                else if (msg.hasOwnProperty('type') && msg.type === 'callid') {
                    this.messageSubject.next({ callid: msg.callid, date: Date.now() });
                }
            }
            catch (er) {
                //console.error(er);
            }
        });
    }
    OnNegotiationNeeded(ev) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            //console.log('OnNegotiationNeeded event called');
            try {
                const offer = yield this.local.createOffer();
                //console.log(`[Singal State] ${this.local.signalingState}`);
                if (this.local.signalingState !== 'stable') {
                    return;
                }
                yield this.local.setLocalDescription(offer);
                this.loggerSubject.next({ type: 'log', msg: `webrtc Offer Sent` });
                this.socket.emit('offer', { name: this.name, peername: this.peername, data: JSON.stringify(this.local.localDescription) });
            }
            catch (err) {
                //console.error(err);
            }
        });
    }
    plugEvents(conn) {
        const OnIceCandidateEvent = fromEvent(conn, 'icecandidate');
        OnIceCandidateEvent.pipe(takeUntil(this.takeUntilSub)).subscribe(ev => {
            this.onIceCandidate(conn, ev);
        });
        const IceStateChange = fromEvent(conn, 'iceconnectionstatechange');
        IceStateChange.pipe(takeUntil(this.takeUntilSub)).subscribe(ev => {
            //console.log(`[CONN STATE] ${conn.iceConnectionState}`);
            this.loggerSubject.next({ type: 'log', msg: `[CONN STATE] ${conn.iceConnectionState}` });
            if (conn.iceConnectionState === 'connected') {
                this.takeUntilSubSocket.next();
                this.takeUntilSubSocket.complete();
                this.socket.disconnect();
            }
            //console.log(ev);
        });
        const OnNegotiationNeededEvent = fromEvent(conn, 'negotiationneeded');
        OnNegotiationNeededEvent.pipe(takeUntil(this.takeUntilSub)).subscribe((ev) => {
            this.OnNegotiationNeeded(ev);
        });
        const OnDataChannelEvent = fromEvent(conn, 'datachannel');
        OnDataChannelEvent.pipe(takeUntil(this.takeUntilSub)).subscribe((ev) => {
            //console.log(ev);
        });
        const OnTrackEvent = fromEvent(conn, 'track');
        OnTrackEvent.pipe(takeUntil(this.takeUntilSub)).subscribe((ev) => {
            this.vidConnected = true;
            this.loggerSubject.next({ type: 'log', msg: 'Track Opened' });
            this.loggerSubject.next({ type: 'action', msg: 'Track Opened', open: true });
            //console.log(ev.streams);
            //console.log(`Got Stream`);
            this.loggerSubject.next({ type: 'log', msg: `Got Stream` });
            this.streamSubject.next({ type: 'remote', stream: ev.streams[0] });
        });
    }
    addIceCandidate(candidates) {
        //console.log(`Adding Ice Candidate`);
        candidates.forEach((candidate) => tslib_1.__awaiter(this, void 0, void 0, function* () {
            const rtcCand = new RTCIceCandidate(candidate);
            this.loggerSubject.next({ type: 'log', msg: `Ice: ${rtcCand.address} - ${rtcCand.relatedAddress}` });
            try {
                yield this.local.addIceCandidate(rtcCand);
            }
            catch (err) {
                //console.error(err);
            }
        }));
    }
    sendIceCandidate(conn, senderid) {
        //console.log(`Sending Ice Candidate`);
        this.socket.emit('candidate', {
            name: this.name, senderid,
            candidates: JSON.stringify(this.iceCandidates.slice())
        });
        this.iceCandidates = [];
        // this.iceCandidates.forEach( candidate => {
        // const rtcCand: any = new RTCIceCandidate(candidate);
        // this.local.addIceCandidate(rtcCand);
        // });
    }
    // OnIceCandidate Event Handler
    onIceCandidate(conn, ev) {
        if (ev && ev.candidate) {
            if (this.vidConnected) {
                this.socket.emit('candidate', {
                    name: this.name, senderid: this.caller ? this.CalleeID : this.CallerID,
                    candidates: JSON.stringify([ev.candidate])
                });
            }
            else {
                this.iceCandidates.push(ev.candidate);
            }
            //console.log(`<<< Received local ICE candidate from STUN/TURN server (${ev.candidate.address})`);
        }
        //console.log(`ICE candidate: ${ev.candidate ? ev.candidate.candidate : '(null)'}`);
    }
    waitForCall() {
        return this.socket.listen('new_call').pipe(first(), tap((res) => {
            if (res && res.caller && res.peername) {
                this.peername = res.peername;
                this.CalleeID = this.socket.id;
                this.CallerID = res.caller;
            }
        }));
    }
    makeCall() {
        return new Promise((resolve, reject) => {
            this.socket.emit('make_call', { caller: this.CallerID, callee: this.CalleeID, name: this.name });
            this.socket.once('receive_ans').pipe(first()).subscribe(({ accept }) => {
                if (accept) {
                    resolve(accept);
                }
                else {
                    reject(accept);
                }
            });
        });
    }
    acceptCall(accept) {
        this.socket.emit('answer_call', { callee: this.CalleeID, name: this.name, caller: this.CallerID, accept });
    }
    cancelCall() {
        this.socket.emit('cancel_call', { receiver: this.caller ? this.CallerID : this.CalleeID, name: this.name, caller: this.CallerID });
    }
    startListening() {
        this.socket.listen('new_offer').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(msg => {
            //console.log(`Got offer`);
            this.loggerSubject.next({ type: 'log', msg: `Socket New Offer Received` });
            this.createConn(false, msg);
        });
        this.socket.listen('new_answer').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(msg => {
            this.loggerSubject.next({ type: 'log', msg: `Socket Answer Received` });
            this.handleAnswer(msg);
        });
        this.socket.listen('end').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(msg => {
            //console.log(`End Session`);
            // this.end();
        });
        this.socket.listen('session_active').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(msg => {
            alert('session_active');
        });
        this.socket.listen('icecandidate').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(payload => {
            const { senderid, candidates } = payload;
            // this.vidConnected = true;
            this.addIceCandidate(JSON.parse(candidates));
            // this.sendIceCandidate(this.local, senderid);
            // const rtcCand: any = new RTCIceCandidate(candidate);
            // console.log('adding ice candidate');
            // this.local.addIceCandidate(rtcCand);
        });
        this.socket.listen('cancel_call').pipe(takeUntil(this.takeUntilSubSocket)).subscribe(payload => {
            //console.log('Cancel Call Called');
            this.cancelCallSubject.next(true);
        });
    }
    disconnect() {
        this.takeUntilSub.next();
        this.takeUntilSub.complete();
        this.myIdSubject.next('');
        this.local.close();
        this.local = null;
        this.name = '';
        this.peername = '';
        this.CallerID = '';
        this.CalleeID = '';
        this.iceCandidates = [];
        this.vidConnected = false;
        this.caller = false;
        this.messageSubject.next({});
        this.loggerSubject.next({});
        this.streamSubject.next({});
        this.connectionEvent.next({});
        this.cancelCallSubject.next(false);
        //console.log(`Calling disconnect`);
        // this.OnIceCandidateSub.unsubscribe();
        // this.OnMessageSub.unsubscribe();
        // this.OnNegotiationNeededSub.unsubscribe();
        // if (this.subList.length > 0) {
        //   this.subList.forEach( sub => {
        //     sub.unsubscribe();
        //   });
        // }
    }
    replaceAudioTrack(track) {
        //console.log(track);
        let senders = this.local.getSenders();
        let sender = senders.find((snd) => {
            //console.log(snd);
            return snd.track.kind == track.kind;
        });
        //console.log('appropriate audio sender is');
        //console.log(sender);
        sender.replaceTrack(track).
            then(() => {
            //console.log('Peer sender replaceAudioTrack succeeded');
        }, (er) => {
            //console.log('Peer sender replaceAudioTrack failed');
            //console.log(er);
        });
    }
    replaceVideoTrack(track) {
        //console.log(track);
        let senders = this.local.getSenders();
        let sender = senders.find((snd) => {
            //console.log(snd);
            return snd.track.kind == track.kind;
        });
        //console.log('appropriate video sender is');
        //console.log(sender);
        sender.replaceTrack(track).
            then(() => {
            //console.log('Peer sender replaceVideoTrack succeeded');
        }, (er) => {
            //console.log('Peer sender replaceVideoTrack failed');
            //console.log(er);
        });
    }
    isCaller() {
        return this.caller;
    }
    checkDeviceSupport() {
        const info = this.deviceService.getDeviceInfo();
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();
        const isDesktopDevice = this.deviceService.isDesktop();
        const mediaDevices = navigator.mediaDevices ? true : false;
        if (isDesktopDevice) {
            if (mediaDevices) {
                this.isSupported.next({ type: 'desktop', state: true, info });
            }
            else {
                this.isSupported.next({ type: 'desktop', state: false, info });
            }
        }
        else if (/ios/i.test(info.os) || /ipad/i.test(info.os)) {
            if (/safari/i.test(info.browser) && mediaDevices) {
                this.isSupported.next({ type: 'ios', state: true, info });
            }
            else {
                this.isSupported.next({ type: 'ios', state: false, info });
            }
        }
        else if (/android/i.test(info.os)) {
            if (mediaDevices) {
                this.isSupported.next({ type: 'android', state: true, info });
            }
            else {
                this.isSupported.next({ type: 'android', state: false, info });
            }
        }
        else if (/(macintosh|mac os)/i.test(info.userAgent)) {
            this.isSupported.next({ type: 'ios', state: false, info });
        }
    }
}
VanillartcService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VanillartcService_Factory() { return new VanillartcService(i0.ɵɵinject(i1.RealTimeService), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.DeviceDetectorService)); }, token: VanillartcService, providedIn: "root" });
