<div class="askForFeedbackWrapper">

    <div class="askForFeedbackBox">

        <div class="askIntro regularWeight" >
            Thank you for using CHIT!<br>
            Your call has ended.
        </div>
        <div class="askForFeedback">
            Would you like to leave feedback?
        </div>
        <div class="askButtons">
            <button mat-raised-button class="bigButton" (click)="answerYes()">
                YES
            </button>
            <button mat-raised-button class="bigButton" (click)="answerNo()">
                NO
            </button>
        </div>
    </div>
</div>
