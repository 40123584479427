import { environment } from 'src/environments/environment';
import * as i0 from "@angular/core";
import * as i1 from "./data.service";
import * as i2 from "./vanillartc.service";
import * as i3 from "ngx-device-detector";
export class FeedbackService {
    constructor(dataService, rtcService, deviceService) {
        this.dataService = dataService;
        this.rtcService = rtcService;
        this.deviceService = deviceService;
        this.dataURL = environment.dataURL;
        this.callid = '';
        this.startsessiontime = null;
        this.endsessiontime = null;
        this.duration = null;
        this.dbId = '';
        this.caller = null;
        this.name = '';
        this.getDeviceInfo();
        this.rtcService.messageSubject.subscribe(msg => {
            if (msg && msg.hasOwnProperty('callid')) {
                //console.log(msg);
                this.callid = msg.callid;
                //
                this.createStartTime();
                this.sendFeedback(false);
            }
        });
        this.rtcService.myIdSubject.subscribe((name) => {
            //console.log(name);
            if (name) {
                this.name = name;
            }
            else {
                //this.name = '';
            }
        });
    }
    get call() { return this.callid; }
    get myname() { return this.name; }
    getDeviceInfo() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
        //console.log(this.deviceInfo);
        this.deviceInfo.user_agent = this.deviceInfo.userAgent;
        delete this.deviceInfo.userAgent;
        //console.log(this.deviceInfo);
    }
    generateCallID() {
        const arr = new Uint32Array(3);
        const cryptoObj = window.crypto || window.msCrypto;
        let datestring = Date.now().toString();
        let newid;
        if (!cryptoObj) {
            //console.log('no crypto obj');
            let numb = Math.random().toString();
            let sliced = numb.slice(2);
            newid = sliced + '-' + datestring;
        }
        else {
            const rands = cryptoObj.getRandomValues(arr);
            const val = rands.reduce((a, b) => a + b, 0);
            let str = /^[a-zA-Z]/.test(val) ? val : `c${val}id`;
            newid = str + '-' + datestring;
        }
        this.callid = newid;
        return newid;
    }
    sendFeedback(caller) {
        this.caller = caller;
        let obj = {
            call_id: this.callid,
            caller: caller
        };
        let toSend = Object.assign(obj, this.deviceInfo);
        //console.log(toSend);
        //does this onlt send on caller or alos callee
        //calle can not send until it has callid from caller....
        this.dataService.postData(this.dataURL + '/feedbacks', toSend).subscribe((suc) => {
            //console.log(suc);
            this.dbId = suc._id;
        }, er => {
            //console.log(er);
            //log error via sentry...
        });
    }
    userUpdateFeedback(numb) {
        //console.log(numb);
        let obj = {
            call_id: this.callid,
            id: this.dbId,
            duration: this.duration,
            experience: numb,
            caller: this.caller
        };
        //console.log(obj);
        return this.dataService.putData(this.dataURL + '/feedbacks', obj);
    }
    autoUpdateFeedback() {
        let obj = {
            call_id: this.callid,
            id: this.dbId,
            duration: this.duration,
            experience: -1,
            caller: this.caller
        };
        //console.log(obj);
        this.dataService.putData(this.dataURL + '/feedbacks', obj).subscribe(dt => {
            //console.log('autoupdated for duration');
            this.reset();
        }, er => {
            //console.log('failed to autoupdate for duration');
            //console.log(er);
            this.reset();
        });
    }
    createStartTime() {
        this.startsessiontime = Date.now();
        //console.log(this.startsessiontime);
    }
    createEndTime() {
        this.endsessiontime = Date.now();
        //console.log(this.endsessiontime);
    }
    createSessionTime() {
        this.duration = this.endsessiontime - this.startsessiontime;
        //console.log(this.duration);
    }
    reset() {
        //console.log('feedbackservice reset');
        this.callid = '';
        this.startsessiontime = null;
        this.endsessiontime = null;
        this.duration = null;
        this.dbId = '';
        this.caller = null;
        this.name = '';
    }
    onUnload() {
        //if((this.callid !== '')  && (this.dbId!== '') && (this.caller !== null)){
        if (this.dbId) {
            if (!this.endsessiontime) {
                this.createEndTime();
                this.createSessionTime();
            }
            this.autoUpdateFeedback();
            //send out session info
        }
    }
    // onUnload(){
    //   this.autoUpdateFeedback();
    // }
    onUnloadV2() {
        if (this.dbId) {
            if (!this.endsessiontime) {
                this.createEndTime();
                this.createSessionTime();
            }
            let obj = {
                call_id: this.callid,
                id: this.dbId,
                duration: this.duration,
                experience: -1,
                caller: this.caller
            };
            //let str=JSON.stringify(obj);
            let bl = new Blob([JSON.stringify(obj)], { type: 'text/plain' });
            navigator.sendBeacon(this.dataURL + '/feedbacks/beacon', bl);
            //send out session info
        }
    }
}
FeedbackService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FeedbackService_Factory() { return new FeedbackService(i0.ɵɵinject(i1.DataService), i0.ɵɵinject(i2.VanillartcService), i0.ɵɵinject(i3.DeviceDetectorService)); }, token: FeedbackService, providedIn: "root" });
