import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FeedbackService } from '../services/feedback.service';
@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.css']
})
export class IntroComponent implements OnInit {
  deviceInfo:any;
  constructor(private router :Router, private feedbackService : FeedbackService) {
    this.feedbackService.reset();
   }

  ngOnInit() {
  }

  signMeUp(){
    this.router.navigate(['signup']);
  }

  join(){
    this.router.navigate(['join']);
  }

  giveItATry(){
    //id :null means not logged in so prompt for chit code creation
    this.router.navigate(['presession', {id : null}]);
  }

}
