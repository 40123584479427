import { Component, HostListener } from '@angular/core';
import { FeedbackService} from './services/feedback.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'chitchat';
  @HostListener('window:beforeunload', ['$event']) beforeUnloadHandler(event){
    this.feedbackService.onUnloadV2();
  }
  constructor(private feedbackService: FeedbackService){}
}
