/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./calling.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./calling.component";
import * as i4 from "@angular/router";
import * as i5 from "../services/vanillartc.service";
var styles_CallingComponent = [i0.styles];
var RenderType_CallingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CallingComponent, data: {} });
export { RenderType_CallingComponent as RenderType_CallingComponent };
function View_CallingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "connectionBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "connectionTextWrapper regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "connectionTextTop"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Calling "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "connectionTextBottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "object", [["class", "logo"], ["data", "./assets/image/BarsAnimate.svg"], ["type", "image/svg+xml"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "awaitingText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" Initializing Session with: ", "... "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.peerid; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.peerid; _ck(_v, 9, 0, currVal_1); }); }
function View_CallingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "connectionBox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "connectionTextWrapper regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "connectionTextTop"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Could Not "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "connectionTextBottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Connect "])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "object", [["class", "logo"], ["data", "./assets/image/Error.svg"], ["type", "image/svg+xml"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "awaitingText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.errorMessage; _ck(_v, 9, 0, currVal_0); }); }
export function View_CallingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallingComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CallingComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.callError; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.callError; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_CallingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-calling", [], null, null, null, View_CallingComponent_0, RenderType_CallingComponent)), i1.ɵdid(1, 245760, null, 0, i3.CallingComponent, [i4.Router, i5.VanillartcService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CallingComponentNgFactory = i1.ɵccf("app-calling", i3.CallingComponent, View_CallingComponent_Host_0, {}, {}, []);
export { CallingComponentNgFactory as CallingComponentNgFactory };
