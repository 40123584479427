import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./vanillartc.service";
import * as i3 from "./feedback.service";
export class RouteGuardService {
    constructor(router, rtcService, feedbackService) {
        this.router = router;
        this.rtcService = rtcService;
        this.feedbackService = feedbackService;
    }
    canActivate(next, state) {
        return this.getValidName();
    }
    getValidName() {
        let Rname = this.rtcService.myname;
        //let call=this.feedbackService.call;
        let Fname = this.feedbackService.myname;
        //console.log(Rname);
        //console.log(call);
        //console.log(Fname);
        //get callid since rtcservice name is already reset by feedback section
        //actually get name from feedback service on off chance callee never got callid from caller
        //this still allows the route to go through, since your name was made regardless of callid
        //if( (Rname!=='' && Rname!==undefined) || call!==''){
        if ((Rname !== '' && Rname !== undefined) || Fname !== '') {
            return true;
        }
        else {
            this.router.navigate(['']);
            return false;
        }
    }
}
RouteGuardService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RouteGuardService_Factory() { return new RouteGuardService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.VanillartcService), i0.ɵɵinject(i3.FeedbackService)); }, token: RouteGuardService, providedIn: "root" });
