import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PeerconnectorService } from '../services/peerconnector.service';
import { Router, NavigationExtras } from '@angular/router';
import { Subscription } from 'rxjs';
import { VanillartcService } from '../services/vanillartc.service';

@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.css']
})
export class CallingComponent implements OnInit {
  callError=false;
  errorMessage='';
  usemobilesession=false;
  peerid:string ='';
  peeridSubscription:Subscription;
  dataSubscription:Subscription;
  peerErrorSubscription:Subscription;
  constructor(private router : Router, private rtcService: VanillartcService) {

  }

  ngOnInit() {
    this.peerid = this.rtcService.peer;
    this.rtcService.makeCall().then( resp => {
      let navigationExtras: NavigationExtras = {
        queryParams: {
            initiated: true,
        }
      }
      //console.log('Call Accepeted');
      this.router.navigate(['mobilesession'], navigationExtras)
    }).catch(err => {
      this.callError = true;
      this.errorMessage = `Call Rejected`;
      setTimeout(() => {
        this.router.navigate(['presession']);
      }, 5000);
      // console.error(`Call Rejected`);
    });
  }

  ngOnDestroy(){
  }
}
