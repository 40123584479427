<div class="errorHeader">
    Got error <span *ngIf="messageString"> message</span> <span *ngIf="messageObject">object</span>:
</div>
<div class="errorMessage" *ngIf="messageString">
    {{messageString}}
</div>
<div class="errorMessage" *ngIf="messageObject">
    {{messageObject | json}}
</div>
<div class="buttonWrapper">
    <button class="dismissButton" mat-raised-button (click)="dismiss()">Dismiss</button>
</div>
