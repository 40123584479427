export const environment = {
  production: true,
  peerserverhost : '/',
  peerserverport : 443,
  // logLevel: 'DEBUG',
  // sentryDSN: 'https://650750cb259246ec95771a3b47249487@o387711.ingest.sentry.io/5223404',
  userserver : '',

  logLevel: 'DEBUG',
  sentryDSN: 'https://650750cb259246ec95771a3b47249487@o387711.ingest.sentry.io/5223404',
  baseURL: `http://localhost:8080`,
  socket: {
    // baseURL: 'http://localhost:5050',
    baseURL: 'https://p2p-broker.chit.rocks',
    opts: {
      // path: '/socket.io-client'
    }
  },
  dataURL: 'https://api-p2p.chit.rocks/v1/api'
};
