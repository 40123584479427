<app-waiting [shouldwait]="processing"></app-waiting>
<div class="feedbackWrapper">
    <div *ngIf="!submitted">
        <form [formGroup]="feedbackForm" novalidate>
            <!-- <label id="yesOrNo" class="labelMargin">1. Would you use CHIT again?</label>
            <mat-radio-group class="yesOrNoGroup" formControlName="useagain">
            <mat-radio-button class="againRadioButton" *ngFor="let again of useagainOptions" [value]="again">
                {{again}}
            </mat-radio-button>
            </mat-radio-group> -->

            <!-- <label id="experienceSelect" class="labelMargin">2. What choice best describes your overall experience?</label>
            <mat-form-field class="selectFormField">
                <mat-label>Experience</mat-label>
                <mat-select  formControlName="experience">
                    <mat-option *ngFor="let experience of experiences" [value]="experience">
                    {{experience}}
                    </mat-option>
                </mat-select>
            </mat-form-field> -->
            <label id="starrateings" class="labelMargin">How would you rate your experience?</label>
            <app-star (starred)="starFeedback($event)"></app-star>

            <!-- <label id="leaveComment" class="labelMargin">3. Please leave any extra comments related to your experience...</label>
            <mat-form-field class="textareaFormField">
                <textarea formControlName="comments" matInput placeholder="Leave a comment" cdkTextareaAutosize #autosize="cdkTextareaAutosize"></textarea>
            </mat-form-field> -->

            <button class="submitButton bigButton" mat-raised-button (click)="submit()">SUBMIT</button>
        </form>

    </div>
    <div *ngIf="submitted">
        <div class="textWrapper">
            <div class="thankyouText semiboldWeight">
                Thank you for your thoughts!
            </div>
            <br>
            <div class="meantimeText regularWeight">
              We are committed to building a better experience.
            </div>
        </div>

        <div class="makecallWrapper">
          <button class="introButtons bigButton" mat-raised-button (click)="join()">JOIN</button> <br>
            <button class="joinButton bigButton" mat-raised-button (click)="continue()">
                CONTINUE
            </button>
        </div>
    </div>
</div>
