<app-waiting [shouldwait]="processing"></app-waiting>
<div *ngIf="!joined">
    <div class="joinTextWrapper" *ngIf="!joinerror">
        <div class="joinText regularWeight">
            Signing up provides some additional features, most importantly, the ability to create your own permanent userID and keep other users as contacts, making initiating calls with friends simple and quick.
        </div>
        <br>
        <div class="joinText regularWeight">
            This is still very much "Early Access," so we will not be signing up new users all at once. Instead, we will be adding users in small batches over the coming months in the order that the've registered.
        </div>
        <br>
        <div class="joinText regularWeight">
            This core service will be free - no strings attached, and will remain so, as long as it's sustainable.
        </div>
    </div>

    <div class="joinTextWrapper" *ngIf="joinerror">
        <div class="joinText regularWeight">
            There appears to be an error in the sign up process.  Please try again below.
        </div>
    </div>

    <div class="joinTextWrapper" *ngIf="alreadysignedup">
        <div class="joinText regularWeight">
            You are already signed up.  Thank you for your enthusiasm.
        </div>
    </div>
    <div class="formWrapper">
        <form [formGroup]="joinForm" novalidate>
          <div class="looper" *ngFor="let control of joinFormControlList;">
            <mat-form-field>
                <input matInput placeholder="Enter Email" [formControlName]="control">
            </mat-form-field>
          </div>
            <div>
                <button class="joinButton bigButton" mat-raised-button (click)="join(joinForm)" [disabled]="isInvalid(joinForm, emailControlName)">
                    ADD ME
                </button>
            </div>
        </form>
    </div>
</div>

<div *ngIf="joined">

    <div class="joinTextWrapper">
        <div class="thankyouText regularWeight">
            Thank you for joining the list!
        </div>
        <br>
        <div class="meantimeText regularWeight">
            In the meantime, Please continue to use Chit's core chat console.
        </div>
    </div>


    <div class="makecallWrapper">
        <button class="joinButton bigButton" mat-raised-button (click)="makeCall()">
            MAKE A CALL
        </button>
    </div>
</div>
