<div *ngIf="showFullHeader" class="headerWrapper">
    <!-- <div class="nav">
        <div class="navigation" *ngIf="useNavigation">
            <div *ngIf="!useHeader">
                <span class="miniTitle boldWeight">CHIT</span>
                <span class="codeText regularWeight" *ngIf="myid"> Code: </span>
                <span id="connectId" *ngIf="myid" class="codeText regularWeight">{{myid}}</span>
                <button mat-icon-button (click)="copyToClipBoard()" *ngIf="myid">
                    <span class="copySVG"></span>
                </button>
            </div>
            <span class="spacer"></span>
            <button mat-icon-button *ngIf="useLeftArrow" (click)="backToIntro()">
                <span class="leftArrowSVG"></span>
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="endSession()" *ngIf="state=='session'">
                END SESSION
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="cancelCall()" *ngIf="state=='call'">
                CANCEL
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="rejectCall()" *ngIf="state=='answercall'">
                CANCEL
            </button>
        </div>
    </div> -->
    <div class="navAlt">
        <!-- <div class="navigation" *ngIf="useNavigation"> -->
            <div *ngIf="!useHeader" class="noWrappingAlt">
                <!-- <span class="miniTitle boldWeight"> CHIT </span> -->
                <div class="logoTitleSmall">
                    <img class="logoImage" src="assets/image/chit-logo.svg">
                </div>

                <span class="codeText regularWeight" *ngIf="myid"> Code: </span>
                <span class="myIdText regularWeight" *ngIf="myid"> {{myid}} </span>
                <button mat-icon-button (click)="copyToClipBoard()" *ngIf="myid">
                    <span class="copySVG"></span>
                </button>
            </div>
            <span class="spacer"></span>
            <div class="rightButtonsWrapper"> 
                <button mat-icon-button *ngIf="useLeftArrow" (click)="backToIntro()">
                    <span class="leftArrowSVG"></span>
                </button>
                <button mat-button class="endSessionButton mediumWeight" (click)="endSession()" *ngIf="state=='session'">
                    END SESSION
                </button>
                <button mat-button class="endSessionButton mediumWeight" (click)="cancelCall()" *ngIf="state=='call'">
                    CANCEL
                </button>
                <button mat-button class="endSessionButton mediumWeight" (click)="rejectCall()" *ngIf="state=='answercall'">
                    CANCEL
                </button>
                <button mat-button class="infoButton regularWeight" (click)="goToInfo()" *ngIf="state=='intro'">
                    Info
                </button>
            </div>
            <!-- <button mat-icon-button *ngIf="useLeftArrow" (click)="backToIntro()">
                <span class="leftArrowSVG"></span>
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="endSession()" *ngIf="state=='session'">
                END SESSION
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="cancelCall()" *ngIf="state=='call'">
                CANCEL
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="rejectCall()" *ngIf="state=='answercall'">
                CANCEL
            </button>
            <button mat-button class="endSessionButton mediumWeight" (click)="goToInfo()" *ngIf="state=='intro'">
                Info
            </button> -->
        <!-- </div> -->
    </div>

    <div class="sessionInitialized regularWeight" *ngIf="peerid">
        <span class="sessionText">Session initialized with: </span> <span class="peerIdText">{{peerid}}</span>
    </div>
    <div class="header"  *ngIf="useHeader">
        <!-- <div class="appTitle boldWeight" (click)="backToIntro()">
            CHIT
        </div> -->
        <div class="logoTitle" *ngIf="useHeader" (click)="backToIntro()">
            <img class="logoImage" src="assets/image/chit-logo.svg">
        </div>
        <div class="subTitle semiboldWeight" *ngIf="state!='info'">
            Peer to Peer Video Chat
        </div>
    </div>
</div>
