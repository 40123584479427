/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i2 from "@angular/material/progress-spinner";
import * as i3 from "@angular/cdk/platform";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./waiting.component";
var styles_WaitingComponent = [".abs-wait[_ngcontent-%COMP%]{\n      position: absolute;\n      top: 0;\n      left: 0;\n      right: 0;\n      bottom: 0;\n    }\n    .wait-container[_ngcontent-%COMP%] {\n      width: 100%;\n      height: 100%;\n      width: 100vw;\n      height: 100vh;\n      text-align: center;\n      background-color: rgba(67, 65, 76, 0.68);\n      z-index: 10;\n    }\n\n    .spinner-container[_ngcontent-%COMP%] {\n      margin: auto;\n      width: 100px;\n      width: fit-content;\n      height: 100px;\n    }"];
var RenderType_WaitingComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_WaitingComponent, data: {} });
export { RenderType_WaitingComponent as RenderType_WaitingComponent };
function View_WaitingComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "abs-wait wait-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "abs-wait spinner-container"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "mat-spinner", [["class", "chit_color mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i1.View_MatSpinner_0, i1.RenderType_MatSpinner)), i0.ɵdid(3, 114688, null, 0, i2.MatSpinner, [i0.ElementRef, i3.Platform, [2, i4.DOCUMENT], [2, i5.ANIMATION_MODULE_TYPE], i2.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 3, 0); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 3)._noopAnimations; var currVal_1 = i0.ɵnov(_v, 3).diameter; var currVal_2 = i0.ɵnov(_v, 3).diameter; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_WaitingComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_WaitingComponent_1)), i0.ɵdid(1, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldwait; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_WaitingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-waiting", [], null, null, null, View_WaitingComponent_0, RenderType_WaitingComponent)), i0.ɵdid(1, 114688, null, 0, i6.WaitingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WaitingComponentNgFactory = i0.ɵccf("app-waiting", i6.WaitingComponent, View_WaitingComponent_Host_0, { shouldwait: "shouldwait" }, {}, []);
export { WaitingComponentNgFactory as WaitingComponentNgFactory };
