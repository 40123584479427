<div class="initiatingrtc" *ngIf="notConnected">
  <div class="connectionBox">
    <div class="connectionTextWrapper regularWeight">
      <!-- <div class="connectionTextTop">
          Calling
      </div>
      <div class="connectionTextBottom">
          {{peerid}}
      </div> -->
      <div>
          <object type="image/svg+xml" data="./assets/image/BarsAnimate.svg" class="logo">
          </object>
      </div>
    </div>
    <div class="awaitingText regularWeight">
        Establishing Connection with: {{peerid}}...
    </div>
  </div>
</div>
<div class="layersWrapper">

    <div class="layer layer1" #layer1 (click)="showChat()">
        <video #theirvideo class="theirvideo" autoplay  playsinline></video>
        <!-- <img class="theirvideo" src="assets/image/testimages/1080by1920_1.png"> -->
        <video #yourvideo class="yourvideo" autoplay playsinline ></video>
        <!-- <img class="yourvideo" src="assets/image/testimages/1920by1080_1.png"> -->
    </div>

    <div class="layer layer3" #layer3 [ngClass]="{ 'hideLayer' : hidelayer }" >
        <div class="navAlt">
            <div class="noWrappingAlt">
                <!-- <span class="miniTitle boldWeight"> CHIT </span> -->
                <div class="logoTitleSmall">
                    <img class="logoImage" src="assets/image/chit-logo.svg">
                </div>
                <span class="codeText regularWeight" *ngIf="myid"> Code: </span>
                <span class="myIdText regularWeight" *ngIf="myid"> {{myid}} </span>
                <button mat-icon-button (click)="copyToClipBoard()" *ngIf="myid">
                    <span class="copySVG"></span>
                </button>
            </div>
            <!-- <span class="spacer"></span> -->
            <span class="spacer"></span>
            <div class="camButtonWrapper">
                <!-- <button mat-icon-button 
                    *ngIf="useSelectDevices && ( videoinputs.length>=2 ||  audioinputs.length>=2 || (canUseAudioOutputSelection && audiooutputs.length>=2))" 
                    class="gearButton" (click)="getEnumeratedDevices()" [matMenuTriggerFor]="devices">
                    <span class="gearSVG"></span>
                </button> -->
                <button mat-icon-button *ngIf="useSelectDevices" class="gearButton" (click)="getEnumeratedDevices()" [matMenuTriggerFor]="devices">
                    <span class="gearSVG"></span>
                </button>
                <mat-menu #devices="matMenu">
                    <button mat-menu-item [matMenuTriggerFor]="videoIn" *ngIf="useSelectVideoIn  &&  !isMobile && videoinputs.length>=2">
                        Video Sources
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="cameraIn" *ngIf="useSelectVideoIn && isMobile">
                        Camera Sources
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="audioIn" *ngIf="audioinputs.length>=2">
                        Audio Sources
                    </button>
                    <button mat-menu-item [matMenuTriggerFor]="audioOut" *ngIf="canUseAudioOutputSelection">
                        Audio Outputs
                    </button>
                    <!-- <button mat-menu-item *ngFor="let audioinput of audioinputs" (click)="setAudioInput(audioinput)">
                        {{audioinput.label}}
                    </button> -->
                </mat-menu>
                <mat-menu #videoIn="matMenu" >
                    <button mat-menu-item *ngFor="let videoinput of videoinputs" (click)="setVideoInput(videoinput)">
                        {{videoinput.label}}
                    </button>
                </mat-menu>
                <mat-menu #cameraIn="matMenu" >
                    <!-- <button mat-menu-item  (click)="setMobileCameraFacingDirection2('user')">
                        Front
                    </button>
                    <button mat-menu-item  (click)="setMobileCameraFacingDirection2('environment')">
                        Back
                    </button> -->
                    <button mat-menu-item *ngFor="let camerainput of mobileVideoinputs" (click)="setVideoInput(camerainput)">
                        {{camerainput.name}}
                    </button>
                </mat-menu>
                <mat-menu #audioIn="matMenu" >
                    <button mat-menu-item *ngFor="let audioinput of audioinputs" (click)="setAudioInput(audioinput)">
                        {{audioinput.label}}
                    </button>
                </mat-menu>
                <mat-menu #audioOut="matMenu" >
                    <button mat-menu-item *ngFor="let audiooutput of audiooutputs" (click)="setAudioOutput(audiooutput)">
                        {{audiooutput.label}}
                    </button>
                </mat-menu>
                <button mat-icon-button *ngIf="!myaudiomuted" (click)="toggleAudio()" class="micButtonV3">
                    <span class="micSVG"></span>
                </button>
                <button mat-icon-button *ngIf="myaudiomuted" (click)="toggleAudio()" class="micButtonV3">
                    <span class="micoffSVG"></span>
                </button>

                <!-- <button mat-icon-button *ngIf="!myaudiomuted" (click)="toggleAudio()" class="micButtonV2">
                    <span class="camSVGold"></span>
                </button>
                <button mat-icon-button *ngIf="myaudiomuted" (click)="toggleAudio()" class="micButtonV2">
                    <span class="camoffSVGold"></span>
                </button> -->

                <!-- <button mat-fab color="primary" class="camButtonV2">
                    <span class="camSVG"></span>
                </button> -->

                <button mat-icon-button *ngIf="!myvideoblack" (click)="toggleVideo()" class="camButtonV3">
                    <span class="camSVG"></span>
                </button>
                <button mat-icon-button *ngIf="myvideoblack" (click)="toggleVideo()" class="camButtonV3">
                    <span class="camoffSVG"></span>
                </button>
            </div>
        </div>
        <div class="sessionInitialized regularWeight" *ngIf="peerid">
            <span class="sessionText">Session initialized with: </span> <span class="peerIdText">{{peerid}}</span>
        </div>
        <div class="endSeessionButtonWrapper">
            <button mat-button class="endSessionButton mediumWeight" (click)="endSession()">
                END SESSION
            </button>
        </div>
        <!-- <div class="dataSession">
                <div class="conversationWrapper" (click)="hideChat()" #scrollMe  [scrollTop]="scrollMe.scrollHeight">
                    <span class="spacer" ></span>
                    <div class="message" *ngFor="let message of conversation"
                        [ngClass]="{ 'theirs' : message.whos=='theirs', 'yours' : message.whos=='yours'}">
                        <span class="semiboldWeight">{{message.from}}</span> : {{message.message}}
                    </div>
                </div>
                <div class="sendMessageWrapper" >
                    <form novalidate class="message-form">
                        <input type="text" class="regularWeight message-box" name="inputmessage" id="inputmessage" placeholder="your message" [(ngModel)]="inputmessage">
                        <button mat-icon-button class="marginright32" (click)="sendMessage()">
                            <span class="rightArrowSVG"></span>
                        </button>
                    </form>
                </div>
        </div> -->
        <div class="dataSessionAlt">
            <div class="conversationWrapper" (scroll)="onScroll($event)" (click)="hideChat()" #scrollMe  [scrollTop]="scrollMe.scrollHeight">
                <span class="spacer" ></span>
                <div class="message" *ngFor="let message of conversation"
                    [ngClass]="{ 'theirs' : message.whos=='theirs', 'yours' : message.whos=='yours'}">
                    <span class="semiboldWeight">{{message.from}}</span> : {{message.message}}
                </div>
                <button mat-mini-fab [ngClass]="{ 'hideScrollButton' : hideScrollButton}" class="scrollDownButton" (click)="scrollToBottom($event)">
                    <!-- <div>&#x2B07;</div> -->
                    <span class="downArrowSVG"></span>
                </button>
            </div>
        </div>
    </div>

    <div class="layer layer4" [ngClass]="{ 'hideLayer' : hidelayer }">
        <div class="sendMessageWrapper posFixed">
            <form novalidate class="message-form">
                <input type="text" class="regularWeight message-box" name="inputmessage" id="inputmessage" placeholder="your message" [(ngModel)]="inputmessage">
                <button mat-icon-button class="marginright32" (click)="sendMessage()">
                    <span class="rightArrowSVG"></span>
                </button>
            </form>
        </div>
    </div>
</div>
