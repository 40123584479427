import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-waiting',
  templateUrl: './waiting.component.html',
  styles: [`
    .abs-wait{
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
    .wait-container {
      width: 100%;
      height: 100%;
      width: 100vw;
      height: 100vh;
      text-align: center;
      background-color: rgba(67, 65, 76, 0.68);
      z-index: 10;
    }

    .spinner-container {
      margin: auto;
      width: 100px;
      width: fit-content;
      height: 100px;
    }
  `]
})
export class WaitingComponent implements OnInit {


  @Input('shouldwait') shouldwait: boolean;

  constructor() { }

  ngOnInit() {
  }

}
