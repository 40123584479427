import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { PeerconnectorService } from '../services/peerconnector.service';
import { Subscription } from 'rxjs';
import { Router, NavigationExtras} from '@angular/router';
import { ErrorMessageService } from '../services/error-message.service';
import { FormControl, Validators, FormGroup, FormBuilder} from '@angular/forms';
import { VanillartcService } from '../services/vanillartc.service';

@Component({
  selector: 'app-presession',
  templateUrl: './presession.component.html',
  styleUrls: ['./presession.component.css']
})
export class PresessionComponent implements OnInit, OnDestroy {
  myChitcode: FormControl;
  peerChitcode:FormControl;
  usemobilesession=false;
  useanswering=false;
  connectedToServer:boolean=false;
  //peer connect
  peerid:string='';
  //server connect
  myInputId:string='';
  connectedId :string='';

  supportMsg = '';
  isNotSupported = false;
  isSupported: Subscription;
  callWaitSub: Subscription;
  peeridSubscription:Subscription;
  myIdSubscription:Subscription;
  navigationExtras: NavigationExtras = {
    queryParams: {
        initiated: false
    }
  }
  // creating formgroups
  newChitForm: FormGroup;
  newChitControlList = []
  connectChitForm: FormGroup;
  connectChitControlList = []
  chitcodeControlName = '';
  connectcodeControlName = '';

  constructor(private router : Router, private errorMessageService : ErrorMessageService,
    private rtcService: VanillartcService, private fb: FormBuilder,
  ) {
      this.myIdSubscription=this.rtcService.myIdSubject.subscribe( (name)=>{
        if(name){
          this.connectedId=name;
          this.connectedToServer=true;
          this.waitForCall()
        }
      });

      this.isSupported = this.rtcService.isSupported.subscribe(({type, state, info}) => {
        // console.log(JSON.stringify(info));
        if (!state) {
          this.isNotSupported = true;
          if (this.newChitForm) {
            this.newChitForm.disable()
          }
          if (!/^https/i.test(location.protocol)) {
            this.supportMsg = 'For your security please allow this site to run on HTTPS and try again. Thank you.';
            return;
          }
          if (type === 'desktop') {
            this.supportMsg = 'Media Device API is not supported on the current browser. Please try latest Chrome for best experience.';
          } else if (type === 'ios') {
            this.supportMsg = 'Current browser doesn\'t support WebRTC. Please try latest Safari for best experience.';
          } else if (type === 'android') {
            this.supportMsg = 'Current browser doesn\'t support WebRTC. Please try latest Chrome browser for best experience.';
          } else {
            this.supportMsg = 'WebRTC unavailable.';
          }
        }
      })

   }

  ngOnInit() {
    // const cryptoObj = window.crypto || window.msCrypto;
    // this.myChitcode=new FormControl('', [Validators.required, Validators.maxLength(16)]);
    // this.peerChitcode=new FormControl('', [Validators.required, this.validatePeerChitcode.bind(this)]);
    this.chitcodeControlName = this.getRandomString('chitcode');
    this.connectcodeControlName = this.getRandomString('connectcode');
    this.newChitForm = this.fb.group({
      [`${this.chitcodeControlName}`]: ['', [Validators.required, Validators.maxLength(16)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
    if (this.isNotSupported) {
      this.newChitForm.disable();
    }
    this.newChitControlList = this.getKeys(this.newChitForm);
    this.connectChitForm = this.fb.group({
      [`${this.connectcodeControlName}`]: ['', [Validators.required, Validators.maxLength(16), this.validatePeerChitcode.bind(this)]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
    });
    this.connectChitControlList = this.getKeys(this.connectChitForm);
  }

  isInvalid(form, controlname) {
    return form.get(controlname).invalid;
    if (form && controlname) {
      const control = form.get(controlname);
      return control.hasOwnProperty('invalid') ? control.invalid : true;
    } else {
      return true;
    }
  }

  getKeys(form) {
    return Object.keys(form.controls);
  }

  getRandomString(controlname) {
    const arr = new Uint32Array(3);
    const cryptoObj = (window as any).crypto || (window as any).msCrypto;
    if (!cryptoObj) {
      return controlname;
    }

    const rands = cryptoObj.getRandomValues(arr);
    const val = rands.reduce((a, b) => a+b, 0)
    return /^[a-zA-Z]/.test(val) ? val : `a${val}`;
  }

  waitForCall(): void {
    this.callWaitSub = this.rtcService.waitForCall().subscribe( resp => {
      this.rtcService.setPeerName(resp.peername);
      this.router.navigate(['answercall'])
    })
  }

  makeServerConnection(form){
    if (form.valid) {
      console.log(`So, You filled out even hidden inputs, Congrats!`);
      return;
    }
    const vals = (Object as any).assign({}, form.value);
    if(vals[this.chitcodeControlName] && !vals.name && !vals.email){
      this.rtcService.registerSelf(vals[this.chitcodeControlName]).then( resp => {
        //console.log(`Created Connection to Server and made chitcode`)
        // this.router.navigate(['mobilesession'], this.navigationExtras)
        this.connectedToServer = true;
      }).catch(err => {
        //console.error(err);
      })
    }
    else{
      //console.error('need an id to connect to the server');
    }
  }

  makeDataAndVideoConnectionAttempt(form){
    if (form.valid) {
      console.log(`So, You filled out even hidden inputs, Congrats!`);
      return;
    }
    const vals = (Object as any).assign({}, form.value);
    if(vals[this.connectcodeControlName] && !vals.name && !vals.email){
      this.rtcService.canConnect(vals[this.connectcodeControlName]).subscribe( resp => {
        let navigationExtras: NavigationExtras = {
          queryParams: {
              initiated: true,
              peerid : this.peerid
          }
        }
        this.router.navigate(['call']);
      }, err => {
        //console.error(err);
      })
    }
    else{
      //console.log('no id to connect to');
      this.errorMessageService.openErrorBottomSheetV2('No chit code supplied to connect to!');
      return;
    }
  }

  getErrorMessage(form, controlname) {
    //console.log(this.myChitcode);
    const control = form.get(controlname);
    return control.hasError('required') ? 'Must supply a value.' :
        control.hasError('maxlength') ? 'Max length 16 characters.' :
            '';
  }

  getPeerErrorMessage(form, controlname) {
    //console.log(this.peerChitcode);
    const control = form.get(controlname);
    return control.hasError('required') ? 'Chit code required.' :
      control.hasError('maxlength') ? 'Max length 16 characters.' :
      control.hasError('chitcodessame') ? 'Peer code same as your code.' : '';
  }

  validatePeerChitcode( c: FormControl){
    //console.log(c);
    return (c.value!=this.connectedId) ? null : {
      chitcodessame : {
        valid : false
      }
    }
  }
  ngOnDestroy(){
    if(this.myIdSubscription){
      this.myIdSubscription.unsubscribe();
    }
    if(this.callWaitSub){
      this.callWaitSub.unsubscribe();
    }
    if (this.isSupported) {
      this.isSupported.unsubscribe();
    }
  }

}
