import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatButtonModule, MatCheckboxModule, 
  MatSidenavModule, MatToolbarModule, MatExpansionModule,
  MatIconModule, MatInputModule, MatRadioModule,
  MatGridListModule, MatCardModule, MatTableModule,
  MatPaginatorModule, MatSortModule, MatMenuModule,
  MatTabsModule, MatSelectModule, MatDialogModule,
  MatSnackBarModule, MatSlideToggleModule, MatButtonToggleModule,
  MatTooltipModule, MatStepperModule, MatProgressSpinnerModule,
  MatBottomSheetModule} from '@angular/material';

import { IntroComponent }  from './intro/intro.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SessionComponent } from './session/session.component';
import { PresessionComponent  } from './presession/presession.component';
import { UsecasesComponent } from './usecases/usecases.component';
import { SessionChatFlyoutComponent } from './session-chat-flyout/session-chat-flyout.component';
import { MobiletestComponent } from './mobiletest/mobiletest.component';
import { JoinComponent } from './join/join.component';
import { CallingComponent } from './calling/calling.component';
import { AnswerCallComponent } from './answer-call/answer-call.component';
import { AskfeedbackComponent } from './askfeedback/askfeedback.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { MobilesessionComponent } from './mobilesession/mobilesession.component';
import { InfoComponent } from './info/info.component';
import { RouteGuardService as RouteGuard, RouteGuardService} from './services/route-guard.service';
const routes: Routes = [
  { path : '', component : IntroComponent, data : { state : 'intro'}},
  //test below
  //{ path : '', component : MobiletestComponent, data : { state : 'mobiletest'}},
  //{ path : '', component : MobilesessionComponent, data : { state : 'mobilesession'}},
  //{ path : '', component : FeedbackComponent, data : { state : 'feedback'}},
  //{ path : 'login', component : LoginComponent, data : { state : 'login'}},
  { path : 'join', component : JoinComponent, data : { state : 'join'}},
  //{ path : 'signup', component : SignupComponent, data : { state : 'signup'}},
  { path : 'info', component : InfoComponent,  data : { state : 'info'}},
  { path : 'presession', component : PresessionComponent, data : { state : 'presession'}},
  //{ path : 'session', component : SessionComponent, data : { state : 'session'}},
  //{ path : 'usecases', component : UsecasesComponent, data : { state : 'usecases'}},
  //{ path : 'sessionflyout', component : SessionChatFlyoutComponent, data : { state : 'sessionflyout'}},
  //{ path : 'mobiletest', component : MobiletestComponent, data : { state : 'mobiletest'}},
  { path : 'answercall', component : AnswerCallComponent, canActivate: [RouteGuard], data : { state : 'answercall'}},
  { path : 'call', component : CallingComponent, canActivate: [RouteGuard], data : { state : 'call'}},
  { path : 'mobilesession', component : MobilesessionComponent, canActivate: [RouteGuard], data : { state : 'mobilesession'}},
  { path : 'askforfeedback', component : AskfeedbackComponent, canActivate: [RouteGuard], data : { state : 'askforfeedback'}},
  { path : 'feedback', component : FeedbackComponent, canActivate: [RouteGuard], data : { state : 'feedback'}},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes),MatButtonModule, MatCheckboxModule, 
    MatSidenavModule, MatToolbarModule, MatExpansionModule,
    MatIconModule, MatInputModule, MatRadioModule,
    MatGridListModule, MatCardModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatMenuModule,
    MatTabsModule, MatSelectModule, MatDialogModule,
    MatSnackBarModule, MatSlideToggleModule, MatButtonToggleModule,
    MatTooltipModule, MatStepperModule, MatProgressSpinnerModule, MatBottomSheetModule],
  exports: [RouterModule, MatButtonModule, MatCheckboxModule, 
    MatSidenavModule, MatToolbarModule, MatExpansionModule,
    MatIconModule, MatInputModule, MatRadioModule,
    MatGridListModule, MatCardModule, MatTableModule,
    MatPaginatorModule, MatSortModule, MatMenuModule,
    MatTabsModule, MatSelectModule, MatDialogModule,
    MatSnackBarModule, MatSlideToggleModule, MatButtonToggleModule,
    MatTooltipModule, MatStepperModule, MatProgressSpinnerModule, MatBottomSheetModule]
})
export class AppRoutingModule { }
