import { Component, OnInit, OnDestroy} from '@angular/core';
import { FeedbackService } from '../services/feedback.service';
import { Router, NavigationExtras } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as Sentry from '@sentry/browser';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit, OnDestroy {
  //useagain:string= null;
  useagainOptions=['Yes', 'No', 'Maybe'];
  selectedExperience:string =null;
  experiences=['Excellent', 'Good', 'Ok', 'Poor', 'Bad'];
  submitted=false;
  feedbackForm:FormGroup;
  starfeedback=0;
  feedbackSubscription: Subscription;
  processing = false;
  constructor(private feedbackService : FeedbackService, private router : Router,
    private fb: FormBuilder) {
      this.createForm();
     }


  createForm(){
    this.feedbackForm= this.fb.group({
      useagain: [''],
      experience : [''],
      comments : ['']
    })
  }
  ngOnInit() {
  }

  submit(){
    this.processing = true;
    this.feedbackSubscription= this.feedbackService.userUpdateFeedback(this.starfeedback).subscribe(
      (suc)=>{
        this.submitted=true;
        this.feedbackService.reset();
        this.processing = false;
      }, err=>{
        this.submitted=true;
        this.feedbackService.reset();
        Sentry.captureException(err);
        this.processing = false;
      }
    )
  }

  continue(){
    this.router.navigate(['presession']);
  }

  join(){
    this.router.navigate(['join']);
  }
  starFeedback(ev){
    this.starfeedback=ev;
  }

  ngOnDestroy(){
    if(this.feedbackSubscription){
      this.feedbackSubscription.unsubscribe();
    }
  }
}
