<div class="infoWrapperV2">
    <div class="sectionHeader semiboldWeight">PRIVATE, HIGH QUALITY VIDEO CHAT</div>

    <div class="sectionWrapper">
        <div class="sectionText regularWeight">
            Chit was developed to enable high quality video chat, without the privacy concerns or the overhead of placing third party servers in the middle of your calls.
        </div>
        <div class="sectionText">
            Any information Chit sees is in effort to create your peer connection or improve user experience through error logging. Your data is never shared or stored otherwise.
        </div>
    </div>

    <div class="sectionHeader semiboldWeight">WHAT CHIT DOESN'T SEE</div>

    <div class="sectionWrapper">
        <div class="sectionText">
            The content of your calls.    
        </div>
    </div>

    <div class="sectionHeader semiboldWeight">WHAT CHIT DOES SEE</div>

    <div class="sectionWrapper">
        <div class="sectionText">
            Your Public Facing IP Address.
        </div>
        <div class="sectionText">
            Chit currently uses Sentry for Error Logging, which sees:
        </div>

        <div class="sectionList">
            - Console Log Messages and Errors
        </div>
        <div class="sectionList">
            - Device Type
        </div>
        <div class="sectionList">
            - Browser Type and Version
        </div>
        <div class="sectionList">
            - Device OS
        </div>
        <div class="sectionList lastItem">
            - Public Facing IP
        </div>

        <div class="sectionText">
            Chit is brought to you with love by Socket.io, WebRTC and your friends at Null Corp.
        </div>
    </div>
</div>