import { Component, OnInit, ViewChild, ElementRef, OnDestroy, NgZone, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { PeerconnectorService} from '../services/peerconnector.service';
import { Router, ActivatedRoute} from '@angular/router';
import { Subscription, Subject, fromEvent } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ErrorMessageService } from '../services/error-message.service';
import * as Sentry from '@sentry/browser';
import { VanillartcService } from '../services/vanillartc.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FeedbackService } from '../services/feedback.service';
@Component({
  selector: 'app-mobilesession',
  templateUrl: './mobilesession.component.html',
  styleUrls: ['./mobilesession.component.css']
})
export class MobilesessionComponent implements OnInit, OnDestroy {
  //session variables
  callID;
  inititatedCallID=false;
  isMobile=false;
  //flow variables
  useFeedback=false;
  useSelectDevices=true;
  useSelectVideoIn=true;
  useSelectAudioOut=true;
  canUseAudioOutputSelection=false;
  //////////////chat variables
  hideScrollButton=false;
  inputmessage='';
  conversation=[];
  peerVideoSubscription:Subscription;
  peerDataSubscription: Subscription;
  myIdSubscription:Subscription;
  peerIdSubscription:Subscription;

  loggerSub: Subscription;
  vidSub: Subscription;
  messageSub: Subscription;
  takeUntilSub = new Subject();
  notConnected = true;
  messages: any[] = [];
  logger: any[] = [];
  //peerjs varaiables
  peerid='';
  myid='';

  //peer video variables
  peerstream:MediaStream = null;
  theirobjecturl=null;
  myAudioEndEventSub:Subscription;
  @ViewChild('theirvideo', {static: false}) theirvideo : ElementRef;

  //my video variables
  yourstream:MediaStream =null;
  myvideoblack=false;
  myaudiomuted=false;
  yourobjecturl=null;
  constraints={video: true, audio: true};
  @ViewChild('yourvideo',{static: false}) yourvideo : ElementRef;

  //layout variables
  @ViewChild('layer1', {static: false}) private layer1: ElementRef;
  @ViewChild('layer2', {static: false}) private layer2: ElementRef;
  @ViewChild('layer3', {static: false}) private layer3: ElementRef;
  @ViewChild('scrollMe', {static: false}) private scrollMe: ElementRef;
  hidelayer=true;

  //track variables
  videoinputs=[];
  mobileVideoinputs=[];
  audioinputs=[];
  audiooutputs=[];
  boundAudioTrackEndHandler;
  boundAudioTrackEndHandlerV2;
  currentAudioTrack;
  currentVideoTrack;


  constructor(private router :Router, public dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private errorMessageService : ErrorMessageService, private ref: ChangeDetectorRef,
    private route : ActivatedRoute, private rtcService: VanillartcService,
    private feedbackService : FeedbackService) {
      this.boundAudioTrackEndHandler=this.audioTrackEndHandler.bind(this);
      this.boundAudioTrackEndHandlerV2=this.audioTrackEndHandlerV2.bind(this);
      this.isMobile= this.deviceService.isMobile() || this.deviceService.isTablet();
      this.getEnumeratedDevices();
   }

  ngOnInit() {
    this.useFeedback = true;
    this.peerid = this.rtcService.peer;
    //this.myid= this.rtcService.myname;
    this.subscriptors()
    if (this.rtcService.amICaller()) {
      this.rtcService.createConn(true)
    }
  }

  private subscriptors(): void {
    this.rtcService.loggerSubject.pipe(
      takeUntil(this.takeUntilSub)
    ).subscribe(msgObj => {
      if (msgObj.type === 'log') {
        //console.log(msgObj);
        this.logger.push(msgObj);
        if (/connected to/i.test(msgObj.msg)) {
          // this.stateMachine.state = 'connected';
          this.notConnected = false;
          //this._hideScrollFunc();
        }
        else if(msgObj.msg==='Data Opened'){
          let isCaller=this.rtcService.isCaller();
          if(!this.inititatedCallID && isCaller){
            //mke id;
            //send id;
            //console.log('Will initiate callID Data Opened message');
            this.inititatedCallID=true;
            this.callID=this.feedbackService.generateCallID();
            this.feedbackService.createStartTime();
            this.feedbackService.sendFeedback(isCaller);
            this.rtcService.sendCallID(this.callID);
          }
          this._hideScrollFunc();
        }
      } else if (msgObj.hasOwnProperty('error')) {
        this.logger.push({
          type: 'err',
          msg: msgObj.error.message
        });
      } else if (msgObj.type === 'action') {
        //console.log(msgObj);
        if (msgObj.open) {
          // this.stateMachine.state = 'connected';
          this.notConnected = false;
          //console.log('opened channel');
        }
      }
      // this.ref.detectChanges();
    });
    this.rtcService.messageSubject.pipe(
      takeUntil(this.takeUntilSub)
    ).subscribe( msg => {
      //console.log(msg);
      if (msg && msg.hasOwnProperty('message')) {
        this.conversation.push(msg);
      } else if (msg && msg.hasOwnProperty('action')) {
        if (msg.action === 'endCall') {
          this.endSession(true);
        }
      }
    });

    this.rtcService.streamSubject.pipe(
      takeUntil(this.takeUntilSub)
    ).subscribe( strm => {
      if (strm.type === 'local') {
        //console.log('Got Stream LOCAL');
        this.yourvideo.nativeElement.srcObject = strm.stream;
        //this.handleAudioTrackEnd(strm.stream);
        //this.handleAudioTrackEndV3(strm.stream);

        // let audioTracks=strm.stream.getAudioTracks();
        // if(audioTracks.length){
        //   console.log('will set up this.currentAudioTrack and call this.handleAudioTrackEndV4');
        //   this.currentAudioTrack=audioTracks[0];
        //   //this.handleAudioTrackEndV4();
        // }
        this.yourvideo.nativeElement.muted = true;
        this.yourstream = strm.stream;
        this.yourvideo.nativeElement.play().then(resp => {
          this.logger.push({type: 'log', msg: '[Success] Local Video Play'});
        }).catch(err => {
          this.logger.push({type: 'err', msg: '[Fail] Local Video Play'});
        });
      } else if (strm.type === 'remote') {
        //console.log(`%c GOT STREAM REMOTE`, `color:red;font-size:34`);
        //console.log(strm);
        this.theirvideo.nativeElement.srcObject = strm.stream;
        this.theirvideo.nativeElement.muted = false;
        this.peerstream = strm.stream;
        this.theirvideo.nativeElement.play().then(resp => {
          this.logger.push({type: 'log', msg: '[Success] remote Video Play'});
        }).catch(err => {
          this.logger.push({type: 'err', msg: '[Fail] remote Video Play'});
        });
      }
    });
  }

  ngAfterViewInit(){
    this.theirvideo.nativeElement.muted=true;
    this.yourvideo.nativeElement.muted=true;
  }

  private _hideScrollFunc () {
    if ("setSinkId" in this.theirvideo.nativeElement) {
      if(this.useSelectAudioOut){
        this.canUseAudioOutputSelection=true;
      }
      else{
        this.canUseAudioOutputSelection=false;
      }

    }
    else{
      //console.log('can NOT use setSinkId');
    }
    // this.theirvideo.nativeElement.muted=true;
    // this.yourvideo.nativeElement.muted=true;

    let scrollIt=this.scrollMe.nativeElement;
    let offsetHeight=scrollIt.offsetHeight;
    let scrollTop=Math.ceil(scrollIt.scrollTop);
    let scrollHeight=scrollIt.scrollHeight;
    if( (offsetHeight + scrollTop) >= scrollHeight){
      this.hideScrollButton=true;
    }
    else{
      this.hideScrollButton=false;
    }
  }

  //layoutfunctions

  showChat(){
    //this.layer1.nativeElement.style.zIndex=1;
    this.hidelayer=false;
  }

  hideChat(){
    //this.layer1.nativeElement.style.zIndex=4;
    this.hidelayer=true;
  }
  //header - nav functions

  copyToClipBoard(){
    let newClipText=this.myid;
    var promise = navigator.clipboard.writeText(newClipText);
    promise.then( ()=>{
    },
    err=>{

    })
  }

  endSession(noSignal?: boolean){
    if (!noSignal) {
      try{
        this.rtcService.sendAction('endCall');
      }
      catch(err){
        //console.log(err);
      }
      //this.rtcService.sendAction('endCall');
    }
    //do this here or???
    this.feedbackService.createEndTime();
    this.feedbackService.createSessionTime();
    //have duration
    if(!this.useFeedback){
      this.backToPresession();
    }
    else{
      //disconnect from session but save my used id string????
      this.goToAskForFeedback();
    }
  }

  goToAskForFeedback(){
    // this.resetConnections();
    //pass in your id if you still needed it after logging out;
    this.router.navigate(['askforfeedback']);
  }

  // resetConnections(){
  //   let connectedToServer=this.peerservice.isPeerConnectedToServer();
  //   console.log(connectedToServer);
  //   if(connectedToServer){
  //     this.peerservice.disconnectFromServer();
  //   }
  //   this.peerservice.resetPeerService();
  //   this.myid='';
  //   this.peerid='';
  // }

  backToPresession(){
    // this.resetConnections();
    this.router.navigate(['presession']);
  }


  toggleAudio(){
    // this.myaudiomuted=!this.myaudiomuted;
    //console.log(this.yourstream);
    let audiotracks=this.yourstream.getAudioTracks();
    if(audiotracks.length>0){
      let audiotrack=audiotracks[0];
      let audioenabled=audiotrack.enabled;
      if(audioenabled){
        audiotrack.enabled=false;
        this.myaudiomuted=true;
      }
      else{
        audiotrack.enabled=true;
        this.myaudiomuted=false;
      }
    }
  }

  toggleVideo(){
    //this.myvideoblack=!this.myvideoblack;
    let videotracks=this.yourstream.getVideoTracks();
    if(videotracks.length>0){
      let videotrack=videotracks[0];
      let videoenabled=videotrack.enabled;
      if(videoenabled){
        videotrack.enabled=false;
        this.myvideoblack=true;
      }
      else{
        videotrack.enabled=true;
        this.myvideoblack=false;
      }
    }
  }

  //chat functions

  scrollToBottom(ev){
    ev.stopPropagation();
    this.scrollMe.nativeElement.scrollTop=this.scrollMe.nativeElement.scrollHeight;
  }

  onScroll(ev){
    let offsetHeight=ev.target.offsetHeight;
    let scrollTop=Math.ceil(ev.target.scrollTop);
    let scrollHeight=ev.target.scrollHeight;
    if( (offsetHeight + scrollTop) >= scrollHeight){
      this.hideScrollButton=true;
    }
    else{
      this.hideScrollButton=false;
    }
  }

  sendMessage(){
    if(this.inputmessage){
      this.rtcService.sendMessage(this.inputmessage);
      // this.conversation.push({ from : this.myid, message : this.inputmessage, whos : 'yours'});
      this.inputmessage='';
    }
    else{
    }
  }

  ngOnDestroy(){
    this.takeUntilSub.next();
    this.takeUntilSub.complete();
    this.rtcService.disconnect();
    if(this.yourstream){
      this.yourstream.getTracks().forEach(track => track.stop());
      this.yourstream=null;
    }
    if(this.peerstream){
      this.peerstream.getTracks().forEach(track => track.stop());
      this.peerstream=null;
    }
    if(this.yourobjecturl){
      URL.revokeObjectURL(this.yourobjecturl);
    }
    if(this.theirobjecturl){
      URL.revokeObjectURL(this.theirobjecturl);
    }
    if(this.myAudioEndEventSub){
      this.myAudioEndEventSub.unsubscribe();
    }
    this.peerid='';
  }

  //test audio tracks disconnect issue

  handleAudioTrackEnd(stream){
    //console.log('stream To handle AudioTrackEnd.................');
    //console.log(stream);
    // if(this.myAudioEndEventSub){
    //   console.log('this.myAudioEndEventSub.unsubscribe() called...');
    //   this.myAudioEndEventSub.unsubscribe();
    // }
    var audioTrack;
    let audioTracks=stream.getAudioTracks();
    if(audioTracks.length){
      audioTrack=audioTracks[0];
      let audioSettings=audioTrack.getSettings();
      //console.log('current audio track settings');
      //console.log(audioSettings);
      var audioEndEvent= fromEvent(audioTrack, 'ended');
      this.myAudioEndEventSub= audioEndEvent.pipe(first()).subscribe(
        (ev)=>{
          this.myAudioEndEventSub.unsubscribe();
          //console.log(ev);
          //console.log('audioTrack ended event for track....');
          //console.log(audioTrack);
          navigator.mediaDevices.getUserMedia({audio:true, video :false}).
            then((audiostream)=>{
              //console.log('new audio only stream');
              let audioOnlyTracks=audiostream.getAudioTracks();
              if(audioOnlyTracks.length){
                let newAudioOnlyTrack=audioOnlyTracks[0];
                //console.log('have new audioOnly track');
                //change yourstream audiotrack
                this.yourstream.removeTrack(audioTrack);
                this.yourstream.addTrack(newAudioOnlyTrack);
                //new track is not muted, by default, so set icon to not muted state
                this.myaudiomuted=false;
                //send track to be replaced on peerconnection sender
                this.rtcService.replaceAudioTrack(newAudioOnlyTrack);
                //handle this new stream to handle new audio track end event
                this.handleAudioTrackEnd(this.yourstream);
              }
              else{
                //console.log('no new audio only track');
              }
            })
        }
      );
    }
    //to see what type of audioinput devices are seen
    navigator.mediaDevices.enumerateDevices().then(dev=>{
      //console.log('enumerated devices....');
      //console.log(dev);
    })
  }

  handleAudioTrackEndV2(stream){
    //console.log('stream To handle AudioTrackEnd.................');
    //console.log(stream);
    // if(this.myAudioEndEventSub){
    //   console.log('this.myAudioEndEventSub.unsubscribe() called...');
    //   this.myAudioEndEventSub.unsubscribe();
    // }
    var audioTrack;
    let audioTracks=stream.getAudioTracks();
    if(audioTracks.length){
      audioTrack=audioTracks[0];
      //console.log(audioTrack);
      audioTrack.onended=(ev)=>{
        //console.log(ev);
        //console.log('audioTrack ended event for track...');
        //console.log(audioTrack);
        navigator.mediaDevices.getUserMedia({audio:true, video :false}).
          then((audiostream)=>{
            //console.log('new audio only stream');
            let audioOnlyTracks=audiostream.getAudioTracks();
            if(audioOnlyTracks.length){
              let newAudioOnlyTrack=audioOnlyTracks[0];
              //console.log('have new audioOnly track');
              //console.log(newAudioOnlyTrack);
              this.yourstream.removeTrack(audioTrack);
              this.yourstream.addTrack(newAudioOnlyTrack);
              this.myaudiomuted=false;
              this.rtcService.replaceAudioTrack(newAudioOnlyTrack);
              this.ref.markForCheck();
              this.handleAudioTrackEndV2(this.yourstream);
            }
            else{
              //console.log('no new audio only track');
            }
          })
      }
    }
    //to see what type of audioinput devices are seen
    navigator.mediaDevices.enumerateDevices().then(dev=>{
      //console.log('enumerated devices....');
      //console.log(dev);
    })
  }


  handleAudioTrackEndV3(stream){
    //console.log('stream To handle AudioTrackEnd.................');
    //console.log(stream);
    // if(this.myAudioEndEventSub){
    //   console.log('this.myAudioEndEventSub.unsubscribe() called...');
    //   this.myAudioEndEventSub.unsubscribe();
    // }
    //var audioTrack;
    let audioTracks=stream.getAudioTracks();
    if(audioTracks.length){
      this.currentAudioTrack=audioTracks[0];
      //console.log('this.currentAudioTrack is');
      //console.log(this.currentAudioTrack);
      // audioTrack=audioTracks[0];
      // console.log(audioTrack);
      this.currentAudioTrack.addEventListener('ended', this.boundAudioTrackEndHandler, {once: true});
    }
    //to see what type of audioinput devices are seen
    navigator.mediaDevices.enumerateDevices().then(dev=>{
      //console.log('enumerated devices....');
      //console.log(dev);
    })
  }

  handleAudioTrackEndV4(){
    this.currentAudioTrack.addEventListener('ended', this.boundAudioTrackEndHandlerV2, {once: true});
  }
  audioTrackEndHandler(ev){
    //console.log(this);
    //console.log(ev);
    this.currentAudioTrack.removeEventListener('ended',this.boundAudioTrackEndHandler, {once: true});
    //console.log('audioTrack ended event for track...');
    //console.log(audioTrack);
    //console.log(this.currentAudioTrack);
    navigator.mediaDevices.getUserMedia({audio:true, video :false}).
      then((audiostream)=>{
        //console.log('new audio only stream');
        let audioOnlyTracks=audiostream.getAudioTracks();
        if(audioOnlyTracks.length){
          let newAudioOnlyTrack=audioOnlyTracks[0];
          //console.log('have new audioOnly track');
          //console.log(newAudioOnlyTrack);
          this.yourstream.removeTrack(this.currentAudioTrack);
          this.yourstream.addTrack(newAudioOnlyTrack);
          // this.currentAudioTrack.removeEventListener('ended',this.boundAudioTrackEndHandler);
          this.myaudiomuted=false;
          this.rtcService.replaceAudioTrack(newAudioOnlyTrack);
          this.ref.markForCheck();
          this.handleAudioTrackEndV3(this.yourstream);
        }
        else{
          //console.log('no new audio only track');
        }
      })
  }

  audioTrackEndHandlerV2(ev){
    //console.log(ev);
    this.currentAudioTrack.removeEventListener('ended',this.boundAudioTrackEndHandlerV2, {once: true});
    //console.log('audioTrack ended event for track...');
    //console.log(audioTrack);
    //console.log(this.currentAudioTrack);
    navigator.mediaDevices.getUserMedia({audio:true, video :false}).
      then((audiostream)=>{
        //console.log('new audio only stream');
        let audioOnlyTracks=audiostream.getAudioTracks();
        if(audioOnlyTracks.length){
          let newAudioOnlyTrack=audioOnlyTracks[0];
          //console.log('have new audioOnly track');
          //console.log(newAudioOnlyTrack);
          this.yourstream.removeTrack(this.currentAudioTrack);
          this.yourstream.addTrack(newAudioOnlyTrack);
          // this.currentAudioTrack.removeEventListener('ended',this.boundAudioTrackEndHandler);
          this.myaudiomuted=false;
          this.rtcService.replaceAudioTrack(newAudioOnlyTrack);
          this.currentAudioTrack=newAudioOnlyTrack;
          this.ref.markForCheck();
          //this.handleAudioTrackEndV4();
        }
        else{
          //console.log('no new audio only track');
        }
      })
  }

  getEnumeratedDevices(){
    navigator.mediaDevices.enumerateDevices().then(dev=>{
      this.audioinputs=this.getAudioInputs(dev);
      this.videoinputs=this.getVideoInputs(dev);
      this.audiooutputs=this.getAudioOutputs(dev);
      //console.log(this.audioinputs);
      //console.log(this.videoinputs);
      //console.log(this.audiooutputs);
      //alert('audioinputs.length=' + this.audioinputs.length + '\n' + 'videoinputs.length=' + this.videoinputs.length);
      if(this.isMobile){
        //var str='Front and/or rear labels:\n';
        let arr=this.videoinputs.map( (el)=>{
          if(el.label.toLowerCase().includes('front')){
            //str+= ('front :' + el.label + ',\n');
            el.name='Front';
            return el;
          }
          else if(el.label.toLowerCase().includes('back')){
            //str+= ('back :' + el.label + ',\n');
            el.name='Back';
            return el;
          }
          else{
            return el;
          }
        })
        this.mobileVideoinputs=this.videoinputs;
      }
    })
  }

  getVideoInputs(arr){
    let temparr=arr.filter( (el)=>{
      return el.kind=='videoinput';
    })
    return temparr;
  }

  getAudioInputs(arr){
    let temparr= arr.filter( (el)=>{
      return el.kind=='audioinput';
    })
    return temparr;
  }

  getAudioOutputs(arr){
    let temparr=arr.filter( (el)=>{
      return el.kind=='audiooutput';
    })
    return temparr;
  }

  setAudioInput(audIn){
    //console.log(audIn);
    let audioconstraints={ audio : {deviceId : { exact : audIn.deviceId}}};
    let audioTracks=this.yourstream.getAudioTracks();
    if(audioTracks.length){
      this.currentAudioTrack=audioTracks[0];
      this.currentAudioTrack.stop();
      //console.log(this.currentAudioTrack);
      navigator.mediaDevices.getUserMedia(audioconstraints).
        then( (audiostream)=>{
          let audioOnlyTracks=audiostream.getAudioTracks();
          if(audioOnlyTracks.length){
            let newAudioOnlyTrack=audioOnlyTracks[0];
            //console.log('have new audioOnly track');
            //console.log(newAudioOnlyTrack);
            this.yourstream.removeTrack(this.currentAudioTrack);
            this.yourstream.addTrack(newAudioOnlyTrack);
            this.myaudiomuted=false;
            this.rtcService.replaceAudioTrack(newAudioOnlyTrack);
            this.ref.markForCheck();
          }
          else{
            //console.log('no new audio only track to replace with');
          }
        })
        .catch(er=>{
          //console.log('error getting new audioStream');
          //console.log(er);
        })
    }
    else{
      //what now?
      //console.log('no current audio track to replace');
    }
  }

  setVideoInput(vidIn){
    //console.log(vidIn);
    let videoconstraints={ video : {deviceId : { exact : vidIn.deviceId}}};
    let videoTracks=this.yourstream.getVideoTracks();
    if(videoTracks.length){
      this.currentVideoTrack=videoTracks[0];
      this.currentVideoTrack.stop();
      //console.log(this.currentVideoTrack);
      navigator.mediaDevices.getUserMedia(videoconstraints).
        then( (videostream)=>{
          let videoOnlyTracks=videostream.getVideoTracks();
          if(videoOnlyTracks.length){
            let newVideoOnlyTrack=videoOnlyTracks[0];
            //console.log('have new videoOnly track');
            //console.log(newVideoOnlyTrack);
            this.yourstream.removeTrack(this.currentVideoTrack);
            this.yourstream.addTrack(newVideoOnlyTrack);
            this.myvideoblack=false;
            this.rtcService.replaceVideoTrack(newVideoOnlyTrack);
            this.ref.markForCheck();
          }
          else{
            //console.log('no new video only track to replace with');
            //alert('no new video only track to replace with');
          }
        })
        .catch(er=>{
          //console.log('error getting new videoStream');
          //console.log(er);
          //alert(er);
        })
    }
    else{
      //what now?
      //console.log('no current video track to replace');
      //alert('no current video track to replace');
    }
  }

  //will not use unless necessary. assumes all devices have same front and rear camera
  setMobileCameraFacingDirection(direction){
    //console.log(direction);
    let videoconstraints={ video : {facingMode : { exact : direction}}};
    let videoTracks=this.yourstream.getVideoTracks();
    if(videoTracks.length){
      this.currentVideoTrack=videoTracks[0];
      this.currentVideoTrack.stop();
      //console.log(this.currentVideoTrack);
      navigator.mediaDevices.getUserMedia(videoconstraints).
        then( (videostream)=>{
          let videoOnlyTracks=videostream.getVideoTracks();
          if(videoOnlyTracks.length){
            let newVideoOnlyTrack=videoOnlyTracks[0];
            //console.log('have new videoOnly track');
            //console.log(newVideoOnlyTrack);
            this.yourstream.removeTrack(this.currentVideoTrack);
            this.yourstream.addTrack(newVideoOnlyTrack);
            this.myvideoblack=false;
            this.rtcService.replaceVideoTrack(newVideoOnlyTrack);
            this.ref.markForCheck();
          }
          else{
            //console.log('no new video only track to replace with');
            //alert('no new video only track to replace with');
          }
        })
        .catch(er=>{
          //console.log('error getting new videoStream');
          //console.log(er);
          //alert(er);
        })
    }
    else{
      //what now?
      //console.log('no current video track to replace');
      //alert('no current video track to replace');
    }
  }

  setAudioOutput(audOut){
    //console.log(audOut);
    this.theirvideo.nativeElement.setSinkId(audOut.deviceId).
      then( ()=>{
        //console.log('Should successfully change audio output sink');
      }, (er)=>{
        //console.log('error setting audio output sinc');
        if(er){
          //console.log(er);
        }
      })
  }
}
