/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./intro.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./intro.component";
import * as i7 from "@angular/router";
import * as i8 from "../services/feedback.service";
var styles_IntroComponent = [i0.styles];
var RenderType_IntroComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IntroComponent, data: {} });
export { RenderType_IntroComponent as RenderType_IntroComponent };
export function View_IntroComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 22, "div", [["class", "introWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "introTextWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "introText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Chit is a simple P2P Video Chat Service that runs in the Browser. "])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "introText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Sessions connect users to one another directly, securely and privately. "])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "introText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" The contents of your call do not pass through our server. "])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "introButtonsWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", "introButtons bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.giveItATry() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["GIVE IT A TRY"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "introTextWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "div", [["class", "introText regularWeight"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" We are also building enhanced features for registered users, so if you like the service, consider joining the waiting list. "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "introButtonsWrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["class", "introButtons bigButton"], ["mat-raised-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.join() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["JOIN"]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 13).disabled || null); var currVal_1 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 21).disabled || null); var currVal_3 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); _ck(_v, 20, 0, currVal_2, currVal_3); }); }
export function View_IntroComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-intro", [], null, null, null, View_IntroComponent_0, RenderType_IntroComponent)), i1.ɵdid(1, 114688, null, 0, i6.IntroComponent, [i7.Router, i8.FeedbackService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IntroComponentNgFactory = i1.ɵccf("app-intro", i6.IntroComponent, View_IntroComponent_Host_0, {}, {}, []);
export { IntroComponentNgFactory as IntroComponentNgFactory };
